import React from 'react';
import { Statuses } from '@components/containers/AdGroupsIndexPage/statuses';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box } from '@mui/material';

export const CampaignPauseActivateRenderer = ({
  status,
}: {
  status: number;
}) => {
  if (status === Statuses.ACTIVE) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <PauseCircleOutlineOutlinedIcon fontSize="small" />
        Pause Campaign
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
      <PlayCircleOutlineIcon fontSize="small" />
      Activate Campaign
    </Box>
  );
};
