import { ThemeProvider } from '@mui/material';
import { defaultTheme_v2 } from '@themes/default_v2';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGetCampaigns } from '@apis/campaigns';
import { Campaign } from 'types/campaign';
import { chunk } from 'lodash';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { Order, useSort } from '@hooks/sort';
import { DefaultStatusKeys } from '@components/containers/CampaignsPage/statuses';

export interface CampaignsTableContextType {
  rowCount: number;
  campaigns: Campaign[];
  isLoading: boolean;
  paginationModel: {
    page: number;
    pageSize: number;
  };
  setPaginationModel: (paginationModel: {
    page: number;
    pageSize: number;
  }) => void;
  handleManageBudget: (campaign: Campaign) => void;
  handleDeleteDraft: (campaign: Campaign) => void;
  handleDuplicateCampaign: (campaign: Campaign) => void;
  handleDraftCampaign: (id: number) => void;
  handleManageCampaign: (campaign: Campaign) => void;
  handleArchiveUnarchiveCampaign: (campaign: Campaign) => void;
  handlePauseActive: (campaign: Campaign) => void;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
}

export const CampaignsTableContext = createContext<CampaignsTableContextType>(
  {
    rowCount: 0,
    campaigns: [],
    isLoading: false,
    paginationModel: {
      page: 0,
      pageSize: 25,
    },
    setPaginationModel: () => {},
    handleManageBudget: () => {},
    handleDeleteDraft: () => {},
    handleDuplicateCampaign: () => {},
    handleDraftCampaign: () => {},
    handleManageCampaign: () => {},
    handleArchiveUnarchiveCampaign: () => {},
    handlePauseActive: () => {},
    sortModel: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
    setSortModel: () => {},
  },
);

export interface CampaignsTableContextProviderProps
  extends Partial<CampaignsTableContextType> {
  children: React.ReactNode;
  statuses: number[];
}

export const CampaignsTableContextProvider = ({
  children,
  statuses,
  ...props
}: CampaignsTableContextProviderProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const { getComparator, stableSort } = useSort();

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const { items, hasMore, isLoading, isValidating, setSize } =
    useGetCampaigns();
  const { page, pageSize } = paginationModel;
  const loadedItemsLength = items.length;

  // We have to load everything since we filter locally
  useEffect(() => {
    if (hasMore && !isLoading) {
      setSize(prev => prev + 1);
    }
  }, [loadedItemsLength, hasMore, setSize, isLoading]);

  const { totalCount, campaigns } = useMemo(() => {
    const { field, sort } = sortModel?.[0] ?? {};
    const isFiltered = statuses !== DefaultStatusKeys;
    const filteredCampaigns = items.filter(
      campaign => statuses.includes(campaign.status as number),
    )
    const totalCount = isFiltered ? filteredCampaigns.length : items.length;

    const campaigns = chunk(
      field ? stableSort(filteredCampaigns, getComparator(sort as Order, field)) : filteredCampaigns,
      pageSize,
    )?.[page];

    return { totalCount, campaigns };
  }, [items, statuses, page, pageSize, sortModel]);

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <CampaignsTableContext.Provider
        value={
          {
            ...props,
            setSortModel,
            sortModel,
            paginationModel,
            setPaginationModel,
            rowCount: totalCount,
            campaigns: campaigns as unknown as Campaign[],
            isLoading: isLoading || isValidating,
          } as CampaignsTableContextType
        }
      >
        {children}
      </CampaignsTableContext.Provider>
    </ThemeProvider>
  );
};

export const useCampaignsTable = () => {
  return useContext(CampaignsTableContext);
};
