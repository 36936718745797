import React, { useContext, useEffect, useRef, } from 'react';
import { LookerEmbedSDK, PagePropertiesChangedEvent } from '@looker/embed-sdk';
import { styled } from '@mui/material';
import AdvertiserContext from './AdvertiserContext';
import { useAPI } from './hooks/api';
import PropTypes from 'prop-types';
import useLookerFilters from './containers/ReportsPage/hooks/useLookerFilters';

const IframeContainer = styled('div')(({ theme }) => ({
  aspectRatio: '16 / 9',
  [theme.breakpoints.up('lg')]: {
    height: 500,
    aspectRatio: 'unset',
  },
  '& > iframe': {
    width: '100%',
    height: '100%',
  },
}));

interface EmbedLookerChartProps {
  dashboard: string;
  setInExplore?: (inExplore: boolean) => void;
}

export const EmbedLookerChart = ({ dashboard, setInExplore, ...rest }: EmbedLookerChartProps) => {
  const adContext = useContext(AdvertiserContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { useGet } = useAPI();
  const { setupDashboard, handleFiltersChanged } = useLookerFilters()

  useEffect(() => {
    if (!adContext.id) return;

    LookerEmbedSDK.init(process.env.LOOKER_BASE_URL ?? "");

    useGet(`/looker-reporting-embed-url/${dashboard}`).then(res => {
      if (containerRef.current) {
        containerRef.current.innerHTML = '';
      }
      makeDashboard(res?.embedUrl);
    });
  }, [adContext?.id, dashboard]);

  const handleSizeChange = (event: PagePropertiesChangedEvent) => {
    if (containerRef.current) {
      containerRef.current.style.height = `${event.height}px`;
    }
  };

  const handleExplore = () => {
    if (setInExplore) {
      setInExplore(true);
    }
    return undefined;
  }

  const makeDashboard = (url: string) => {
    if (!containerRef.current) return;
    LookerEmbedSDK.createDashboardWithUrl(url)
      .appendTo(containerRef.current)
      .on('page:properties:changed', handleSizeChange)
      .on('dashboard:filters:changed', handleFiltersChanged)
      .on('explore:ready', handleExplore)
      .build()
      .connect()
      .then(setupDashboard)
      .catch(error => {
        console.error('An unexpected error occurred', error);
      });
  };

  return (
    <IframeContainer ref={containerRef} {...rest} />
  );
};

EmbedLookerChart.propTypes = {
  dashboard: PropTypes.string.isRequired,
  setInExplore: PropTypes.func,
};
