import {
  defaultValues,
  fields,
} from '@v2/components/campaign/CampaignAdGroupSection/constants';
import { set } from 'lodash';
import {
  endDateValidation,
  endTimeValidation,
  getBoundingDates,
  startDateSimpleValidation,
  startTimeSimpleValidation,
  maxCPMValidation,
  bidStrategyEventValidation,
  getBidStrategyNameToIdMap,
} from '../validations';
import { object } from 'yup';

export { defaultValues, fields };

export const formSchema = ({ campaign, bidStrategies, roles, domain }) => {
  const { minStartDate, maxEndDate, maxStartDate } = getBoundingDates(
    campaign?.start_date,
    campaign?.end_date,
  );
  const bidStrategyNameToIdMap = getBidStrategyNameToIdMap(
    bidStrategies ?? [],
  );

  const schema = Object.keys(fields).reduce((acc, key) => {
    const obj = { ...acc };
    const nextField = fields[key];
    switch (nextField.path) {
      case fields.startDate.path:
        set(
          obj,
          fields.startDate.path,
          startDateSimpleValidation(fields.endDate.path, {
            min: minStartDate,
            max: maxStartDate,
          }),
        );
        break;
      case fields.endDate.path:
        set(
          obj,
          fields.endDate.path,
          endDateValidation(
            fields.startDate.path,
            fields.endTime.path,
            {
              max: maxEndDate,
            },
          ),
        );
        break;
      case fields.startTime.path:
        set(
          obj,
          fields.startTime.path,
          startTimeSimpleValidation(
            fields.startDate.path,
            fields.endDate.path,
            fields.endTime.path,
            {
              min: minStartDate,
              max: maxStartDate,
            },
          ),
        );
        break;
      case fields.endTime.path:
        set(
          obj,
          fields.endTime.path,
          endTimeValidation(
            fields.startDate.path,
            fields.startTime.path,
            fields.endDate.path,
            {
              max: maxEndDate,
            },
          ),
        );
        break;
      case fields.maxCPMBid.path:
        set(
          obj,
          fields.maxCPMBid.path,
          maxCPMValidation({
            maxCPMBidEnabledFieldName: fields.maxCPMBidEnabled.path,
            bidStrategyFieldName: fields.adGroupBidStrategy.path,
            bidStrategyToIdMap: bidStrategyNameToIdMap,
            roles,
            domain,
          }),
        );
        break;
      case fields.adGroupBidStrategyEvent.path:
        set(
          obj,
          fields.adGroupBidStrategyEvent.path,
          bidStrategyEventValidation(bidStrategyNameToIdMap),
        );
        break;
      default:
        if (nextField.rule) {
          set(obj, nextField.path, nextField.rule);
        }
        break;
    }

    return obj;
  }, {});

  return object().shape(schema);
};

export const dateFields = [
  fields.startDate.path,
  fields.endDate.path,
  fields.startTime.path,
  fields.endTime.path,
];
