import { object } from 'yup';
import { fields } from './constants';
import { defaultCampaignName } from './utils';
import { isFunction } from 'lodash';

export const defaultValues = () => {
  const defaultVals = Object.entries(fields).reduce(
    (acc, [, value]) => ({
      ...acc,
      [value.path]: value.defaultValue,
    }),
    {}
  );
  // set campaign default name explictly here as it needs real-time update
  defaultVals[fields.name.path] = defaultCampaignName();
  return defaultVals;
};

const objectiveGoalsRelatedPaths = [
  fields.objectiveGoalEvent.path,
  fields.objectiveGoalType.path,
  fields.objectiveGoalValueCost.path,
  fields.objectiveGoalValueMultiplier.path,
];

export const validationSchema = ({ campaign, objectiveGoalsAllowed }) => {
  const schema = Object.keys(fields).reduce((acc, key) => {
    const nextField = fields[key];
    if (
      nextField.rule &&
      (objectiveGoalsAllowed ||
        !objectiveGoalsRelatedPaths.includes(nextField.path))
    ) {
      acc[nextField.path] = isFunction(nextField.rule)
        ? nextField.rule({
            campaign,
          })
        : nextField.rule;
    }
    return acc;
  }, {});
  return object().shape(schema);
};
