import React from 'react';
import { useCampaignsTable } from './CampaignsTableContext';
import {
  DataGrid,
  DataGridColumn,
  PinnedPosition,
} from '@v2/components/ui/DataGrid/DataGrid';
import { CampaignActionMenuCellRenderer } from '@components/containers/CampaignsPage/renderers/CampaignActionMenuCellRenderer';
import { CampaignStatusCellRenderer } from '@components/containers/CampaignsPage/renderers/CampaignStatusCellRenderer';
import { CampaignNameCellRenderer } from '@components/containers/CampaignsPage/renderers/CampaignNameCellRenderer';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';

const CampaignsTableV2 = () => {
  const {
    rowCount,
    campaigns,
    isLoading,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
  } = useCampaignsTable();

  return (
    <DataGrid
      loading={isLoading}
      rows={campaigns}
      paginationMode="server"
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      sortModel={sortModel}
      onSortModelChange={setSortModel}
      rowCount={rowCount ?? 0}
      columns={
        [
          {
            field: 'id',
            headerName: 'ID',
            width: 100,
            pinned: PinnedPosition.LEFT,
            resizable: false,
            hideable: false,
          },
          {
            field: 'name',
            headerName: 'Campaign',
            width: 350,
            renderCell: CampaignNameCellRenderer,
            pinned: PinnedPosition.LEFT,
            resizable: false,
            hideable: false,
          },
          {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: CampaignStatusCellRenderer,
            pinned: PinnedPosition.LEFT,
            filterable: false,
            resizable: false,
            hideable: false,
          },
          {
            field: 'action',
            headerName: '',
            width: 50,
            renderCell: CampaignActionMenuCellRenderer,
            pinned: PinnedPosition.LEFT,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            field: 'start_date',
            headerName: 'Start Date',
            minWidth: 120,
            renderCell: DateCellRenderer,
            filterable: false,
          },
          {
            field: 'end_date',
            headerName: 'End Date',
            minWidth: 120,
            // eslint-disable-next-line new-cap
            renderCell: params => DateCellRenderer(params, 'No end date'),
            filterable: false,
          },
          {
            field: 'daily_budget',
            headerName: 'Daily Budget',
            minWidth: 120,
            renderCell: CurrencyCellRenderer,
            sortable: false,
            filterable: false,
          },
          {
            field: 'stats_cache.total_spend',
            headerName: 'Spend',
            minWidth: 120,
            valueGetter: DotNotationValueGetter,
            renderCell: CurrencyCellRenderer,
            sortable: false,
          },
          {
            field: 'stats_cache.budget_remaining',
            headerName: 'Budget Remaining',
            minWidth: 150,
            valueGetter: DotNotationValueGetter,
            renderCell: CurrencyCellRenderer,
            sortable: false,
            filterable: false,
          },
          {
            field: 'stats_cache.action_count',
            headerName: 'Primary Outcome',
            minWidth: 150,
            flex: 1,
            valueGetter: DotNotationValueGetter,
            sortable: false,
            filterable: false,
          },
          {
            field: 'stats_cache.cost_per_visit',
            headerName: 'Cost per Visit',
            minWidth: 150,
            valueGetter: DotNotationValueGetter,
            renderCell: CurrencyCellRenderer,
            sortable: false,
            filterable: false,
          },
        ] as DataGridColumn[]
      }
    />
  );
};

export default CampaignsTableV2;
