import React from 'react';
import numeral from 'numeral';
import { GeoItem, GroupedGeoItem } from '../GeoTargeter/types';
import { distinctGroupsNames, distinctNames } from '../GeoTargeter/constants';
import { Creative } from '../../../../types/creative';

export const getActualGeoNameField = (value: GeoItem) =>
  value.place_name ?? value.text ?? value.name ?? value.label ?? '';

export const getGroupedLabel = (
  values: string[],
  type: (typeof distinctNames)[keyof typeof distinctNames]
) => {
  let label = values.slice(0, 3).join(', ');
  if (values.length > 3) {
    label += ` + ${values.length - 3} more...`;
  }
  label += ` ${distinctGroupsNames[type]}`;
  return label;
};

export const groupGeo = <T extends GeoItem>(
  geos: T[] = []
): GroupedGeoItem | undefined => {
  if (!geos.length) return;

  const type = geos[0].type;

  return {
    id: `geo-${type}-${geos[0].blacklist ? 'exclude' : 'include'}`,
    list: geos,
    type,
    blacklist: geos[0].blacklist ?? false,
  };
};

export const getUnlockedContent = (option: any, unlockedContent: any) => {
  switch (option.display_name) {
    case 'Max Impressions':
    case 'Auto Bid':
    case 'Max ROAS':
      return unlockedContent.maxCpm({ required: false });
    case 'Manual Bid':
    case 'Manual CPM':
      return unlockedContent.maxCpm({ required: true });
    case 'Max Outcomes':
      return (
        <>
          {unlockedContent.eventPicker()}
          {unlockedContent.maxCpm({ required: false })}
        </>
      );
    default:
      return null;
  }
};

export const getLockedStatus = (option: any, bidStrategyEventSet: any) => {
  switch (option.display_name) {
    case 'Max Outcomes':
      return !bidStrategyEventSet || bidStrategyEventSet.length === 0;
    default:
      return false;
  }
};

export const getLockedInfo = (option: any, lockedInfo: any) => {
  switch (option.display_name) {
    case 'Max Outcomes':
      return lockedInfo.trackingLockedInfo;
    default:
      return null;
  }
};
export const getTargetingValue = (
  key: string,
  targeting: { key: string; value: string }[] = []
) => targeting.find(t => t.key === key);

const getAdjustedBidRange = (range: number[]) =>
  range.map(n => n * 1.18 + 0.4);

export const getRecommendedBidRange = ({
  bundles,
  inventoryOption,
  peacock,
  isInternal,
}: {
  bundles: any;
  inventoryOption: any;
  peacock: any;
  isInternal: boolean;
}) => {
  switch (inventoryOption) {
    // TODO: When DS returns a suggested bid range in the future, we should
    // display it in the 'performanceOptimized' case. In the meantime,
    // 'performanceOptimized' gets the default behavior that also applies to
    // 'premium-reach'.

    // case 'performanceOptimized': {
    //   return;
    // }
    case 'bundles': {
      return bundles
        .map(({ adjusted_cpm }: { adjusted_cpm: any }) => adjusted_cpm)
        .filter((cpm: number) => !!cpm)
        .sort((a: number, b: number) => a - b);
    }
    case 'premium-reach':
    default: {
      const range = peacock ? (isInternal ? [17, 21] : [18, 21]) : [10, 20];
      return getAdjustedBidRange(range);
    }
  }
};

export const getRangeText = (range: number[]) => {
  const min = range[0];
  const max = range[range.length - 1];

  if (min === max) {
    return `${numeral(Math.ceil(min)).format('$0,0')}`;
  }

  return `${numeral(min).format('$0,0')} - ${numeral(Math.ceil(max)).format(
    '0,0'
  )}`;
};

export const mapCreativesWithLocalAssets = (
  creatives: Creative[],
  localAssets: Map<number, string>
) =>
  creatives.map(creative => ({
    ...creative,
    preview_url: creative.preview_url ?? localAssets.get(creative.id) ?? null,
  }));
