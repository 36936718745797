import React from 'react';
import { Themes } from '../constants';

const AdvertiserContext = React.createContext({
  id: null,
  name: '',
  theme: Themes.NBCU,
  cost_model: null,
  primary_org: '',
  updateAdvertiser: null,
  url: '',
  uses_looker: false,
  looker_validated: false,
  path_to_purchase_validated: false,
  has_incrementality: false,
  has_sequential_events: false,
  tvsciq: '',
  bidstrategy_set: [],
});

AdvertiserContext.displayName = 'AdvertiserContext';

export default AdvertiserContext;
