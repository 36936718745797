import React from 'react';
import { Statuses } from '@components/containers/AdGroupsIndexPage/statuses';
import { generatePath } from 'react-router-dom';
import { RoutePaths } from '@constants/routes';
import { GridCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import { useQuery, useWizardRedirect } from '@components/hooks';

export const CampaignNameCellRenderer = (
  params: GridCellParams<any, any>
) => {
  const { status, id } = params.row ?? {};
  const { isWizardEnabled } = useWizardRedirect();
  const urlQuery = useQuery();

  if (!id) {
    return null;
  }

  return (
    <Box
      component={Link}
      data-testid="campaign-link"
      sx={{
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: '700',
        color: 'rgba(29, 175, 255, 1)',
      }}
      to={
        status === Statuses.DRAFT
          ? isWizardEnabled
            ? {
                pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, {
                  campaignId: id,
                }),
                search: urlQuery.toString(),
              }
            : generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, {
                id: id,
              })
          : generatePath(RoutePaths.AD_GROUPS, {
              campaignId: id,
            })
      }
    >
      {params.value}
    </Box>
  );
};
