import React, {
  Suspense,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Toolbar,
  Typography,
  Checkbox,
  Box,
  Button,
  MenuItem,
  InputLabel,
  Select,
  ListItemText,
  FormControl,
} from '@mui/material';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import moment from 'moment-timezone';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AdvertiserContext from '../../AdvertiserContext';
import AddAdGroup from '../../AddAdGroup';
import ManageAdGroup from '../../ManageAdGroup';
import ManageBid from '../../ManageBid';
import EditWeighting from '../../EditWeighting';
import ManageDisplay from '../../ManageDisplay';
import { useAPI } from '../../hooks/api';
import { useCampaigns } from '../../hooks/campaigns';
import { useFlags, useUser } from '../../hooks';
import { StatusList, StatusKeys, StatusNames, Statuses } from './statuses';
import { useWizardRedirect } from '@components/hooks/wizardRedirect';
import { editingStates } from '@components/WizardContainers';
import { useDomain } from '@hooks/domain';
import {
  StyledAppHeader,
  classes,
} from '@components/containers/AdGroupsIndexPage/styles';
import { AdGroupsTable } from './AdGroupsTable';
import { useCampaignAdGroupsPage } from '@apis/adGroups';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

const AdGroupsIndexPage = props => {
  const { user } = useUser();
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { campaignId } = useParams();
  const { useGet, usePost, usePatch } = useAPI();
  const { getCampaignData } = useCampaigns();
  const { flags, Flags } = useFlags();
  const { v2AdgroupsLineItems } = useLDFlags();

  const [campaign, setCampaign] = useState({});
  const [sharedAdGroup, setSharedAdGroup] = useState({});
  const [statuses, setStatuses] = useState(StatusKeys);
  const [hasControl] = useState(false);
  const [isControlGroup, setIsControlGroup] = useState(false);
  const [isEditBid, setIsEditBid] = useState(false);
  const [isEditWeighting, setIsEditWeighting] = useState(false);
  const [isAddAdGroup, setIsAddAdGroup] = useState(false);
  const [isManageAdGroup, setIsManageAdGroup] = useState(false);
  const [isManageDisplay, setIsManageDisplay] = useState(false);
  const [isNewAdGroup, setIsNewAdGroup] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [currentAdGroup, setCurrentAdGroup] = useState(null);
  const [currentAdGroupCreatives, setCurrentAdGroupCreatives] =
    useState(null);
  const { update: updateAdGroup, duplicate: duplicateAdGroup, mutate: refreshAdGroups } =
    useCampaignAdGroupsPage(campaignId, {
      v1: !v2AdgroupsLineItems,
    });
  const { mutate: refreshDisplays } = useCampaignAdGroupsPage(
    campaignId,
    {
      v1: true,
    },
    '/static_display_lineitems/',
  );

  const { newGroup, editGroup } = useWizardRedirect();

  const isVerticalCampaignFlowEnabled =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];

  const showVastTag = useMemo(
    () => domain.default || user?.is_tvsci_employee,
    [user, domain.default],
  );

  useEffect(() => {
    if (adContext && adContext.id !== null && campaignId) {
      initCampaign(campaignId);
    }
  }, [campaignId, adContext.id]);

  useEffect(() => {
    if (hasSaved) {
      initCampaign(campaignId);
    }
  }, [hasSaved]);

  const handleStatusesChange = event => {
    const { value } = event.target;

    if (value[value.length - 1] === 'all') {
      setStatuses(statuses.length === StatusKeys.length ? [] : StatusKeys);

      return;
    }

    setStatuses(value);
  };

  async function initCampaign(id) {
    try {
      const campaignData = await getCampaignData(id);

      const { budget, end_date, start_date, url: campaign } = campaignData;

      setCampaign(campaignData);

      setSharedAdGroup({
        budget,
        campaign,
        end_date,
        start_date,
      });
    } catch (error) {
      console.log(error);
    }
  }

  function getStartDateForDupe(start) {
    // if start date is expired, set as today
    if (moment(start).isBefore()) {
      return moment(new Date()).utc().format();
    }

    // if start date is in the future, use same start date
    return start;
  }

  function getEndDateForDupe(end, campaign) {
    // if end date is expired, use campaign end date
    if (end && moment(end).isBefore()) {
      return campaign.end_date;
    }

    if (!end) {
      return null;
    }

    // if end date is in the future, use same end date
    return end;
  }

  const handleAddAdGroup = () => {
    if (isVerticalCampaignFlowEnabled) {
      return newGroup({ campaignId });
    }

    const blankAdGroup = {
      active: true,
      budget: null,
      campaign: null,
      cpm: '',
      creatives: [],
      daily_budget: '00',
      draft: false,
      end_date: null,
      freq_caps: [],
      name: '',
      start_date: null,
      targeting: JSON.stringify({
        advanced: false,
        age: [2, 65],
        bundles: [],
        dayparting: [],
        gender: 'All',
        geo: {
          cities: [],
          countries: [
            {
              place_name: 'Entire US',
              id: 'entire-us',
              blacklist: false,
            },
          ],
          dmas: [],
          states: [],
          zipcodes: [],
        },
        income: [30, 250],
        inventory: 'reach',
      }),
      ...sharedAdGroup,
    };

    setIsNewAdGroup(true);
    setHasSaved(false);
    setCurrentAdGroup(blankAdGroup);
    setIsAddAdGroup(true);
  };

  const handleManageAdGroup = group => {
    if (isVerticalCampaignFlowEnabled) {
      return editGroup({
        campaignId,
        groupId: group.id,
        query: {
          editing: editingStates.single,
        },
      });
    }

    if (group && group.is_control_group) {
      return setIsControlGroup(true);
    }

    setCurrentAdGroup(group);
    setHasSaved(false);
    setIsManageAdGroup(true);
  };

  const handleDuplicateAdGroup = async group => {
    const { name, start_date, end_date, daily_budget } = group;
    const startDate = getStartDateForDupe(start_date);
    const endDate = getEndDateForDupe(end_date, campaign);

    try {
      const extraData = {
        name: `Copy of ${name}`,
        start_date: startDate,
        end_date: endDate,
        daily_budget,
      };
      const response = await usePost(
        `lineitems/${group.id}/duplicate`,
        extraData,
      );

      if (response) {
        console.log('response from duplicate', response);
      }

      duplicateAdGroup(group.id, {
        ...extraData,
        draft: true,
        status: Statuses.DRAFT,
      });
      return response;
    } catch (error) {
      console.error('Error duplicating campaign', error);
    }
  };

  const getCreativeAdGroups = adGroupId => {
    return useGet('/creative_lineitems', {
      params: {
        line_item_id: adGroupId,
        expand: 'creative',
      },
    });
  };

  const patchCreativeAdGroup = (id, data) =>
    usePatch(`/creative_lineitems/${id}`, data);

  const handleIsDuplicatable = adGroup => {
    const { end_date } = adGroup;

    return (
      end_date &&
      moment(end_date).isBefore() &&
      moment(campaign.end_date).isBefore()
    );
  };

  const handleManageDisplay = display => {
    if (isVerticalCampaignFlowEnabled) {
      return editGroup({
        campaignId,
        groupId: `static-${display.id}`,
        query: {
          editing: editingStates.single,
        },
      });
    }

    setCurrentAdGroup(display);
    setHasSaved(false);
    setIsManageDisplay(true);
  };

  const handleCloseAll = () => {
    setIsAddAdGroup(false);
    setIsControlGroup(false);
    setIsManageAdGroup(false);
    setIsManageDisplay(false);
    setIsNewAdGroup(false);
    setCurrentAdGroup(null);
    refreshAdGroups();
    refreshDisplays();
  };

  const handleOpenWeighting = async group => {
    const { results: creativeAdGroups } = await getCreativeAdGroups(group.id);

    const adGroupCreatives = creativeAdGroups.map(creativeAdGroup => ({
      ...creativeAdGroup.creative_object,
      weighting: creativeAdGroup.weighting,
      creativeAdGroupId: creativeAdGroup.id,
    }));

    setCurrentAdGroupCreatives(adGroupCreatives);
    setCurrentAdGroup(group);
    setIsEditWeighting(true);
  };

  const handleWeightingSubmit = async ({ weightedDelivery, creatives }) => {
    const adGroup = await updateAdGroup({
      id: currentAdGroup.id,
      creative_weighting_method: weightedDelivery ? 'WEIGHTED' : 'RANDOM',
    });

    if (adGroup.creative_weighting_method === 'WEIGHTED') {
      const requests = creatives.map(({ weighting, creativeAdGroupId }) =>
        patchCreativeAdGroup(creativeAdGroupId, { weighting }),
      );

      await Promise.all(requests);
    }

    handleCloseWeighting();
  };

  const handleCloseWeighting = () => {
    setIsEditWeighting(false);
    setCurrentAdGroup(null);
    setCurrentAdGroupCreatives(null);
  };

  const handleOpenBid = group => {
    setCurrentAdGroup(group);
    setHasSaved(false);
    setIsEditBid(true);
  };

  const handleCloseBid = () => {
    setIsEditBid(false);
    setCurrentAdGroup(null);
  };

  return (
    <StyledAppHeader history={props.history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong>
            <Link className={classes.link} to={`/campaigns/`}>
              Campaigns
            </Link>
          </strong>{' '}
          &gt; Ad Groups
        </Typography>
      </Box>

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius="20px"
      >
        <Toolbar disableGutters>
          <Grid container>
            <Grid
              container
              item
              alignItems="center"
              justifyContent="space-between"
              xs={12}
            >
              <Grid item xs={3}>
                <Typography variant="h6" component="div">
                  Ad Groups
                </Typography>
              </Grid>

              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                xs={9}
              >
                <Grid item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="status-multiple-checkbox-label">
                      Status
                    </InputLabel>
                    <Select
                      variant="standard"
                      multiple
                      color="secondary"
                      id="status-multiple-checkbox"
                      labelId="status-multiple-checkbox-label"
                      label="Status"
                      value={statuses}
                      onChange={handleStatusesChange}
                      renderValue={selected => {
                        const values = selected
                          .map(item => StatusNames[item])
                          .join(', ');
                        const text =
                          selected.length === StatusList.length
                            ? 'All'
                            : values;

                        return `Showing [${text}] status`;
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 48 * 4.5 + 8,
                            width: 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="all">
                        <Checkbox
                          checked={statuses.length === StatusList.length}
                          indeterminate={
                            statuses.length > 0 &&
                            statuses.length < StatusList.length
                          }
                        />
                        <ListItemText primary="Select All" />
                      </MenuItem>
                      {StatusList.map(({ name, value }) => (
                        <MenuItem key={value} value={value}>
                          <Checkbox checked={statuses.includes(value)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  {campaign && !campaign.experiment_type && (
                    <Button
                      color="secondary"
                      onClick={handleAddAdGroup}
                      startIcon={<AddIcon />}
                    >
                      Add Ad Group
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>

        <ErrorBoundary fallback={<h2>Could not fetch ad groups.</h2>}>
          <Suspense fallback={<h2>Loading...</h2>}>
            <AdGroupsTable
              handleManageAdGroup={handleManageAdGroup}
              handleDuplicateAdGroup={handleDuplicateAdGroup}
              handleOpenWeighting={handleOpenWeighting}
              handleOpenBid={handleOpenBid}
              handleIsDuplicatable={handleIsDuplicatable}
              handleManageDisplay={handleManageDisplay}
              campaignId={campaignId}
              campaign={campaign}
              statuses={statuses}
            />
          </Suspense>
        </ErrorBoundary>

        {isAddAdGroup && (
          <AddAdGroup
            adGroup={currentAdGroup}
            campaign={campaign}
            isOpen={isAddAdGroup}
            isNew={isNewAdGroup}
            showVastTag={showVastTag}
            onClose={handleCloseAll}
            setCampaign={setCampaign}
            setHasSaved={setHasSaved}
            showGenre={domain.peacock}
            showType={domain.default}
          />
        )}

        {isManageAdGroup && (
          <ManageAdGroup
            adGroup={currentAdGroup}
            campaign={campaign}
            hasControlAdGroup={hasControl}
            isModal
            showVastTag={showVastTag}
            showGenre={domain.peacock}
            isNew={isNewAdGroup}
            isOpen={isManageAdGroup}
            onClose={handleCloseAll}
            setCampaign={setCampaign}
            setHasSaved={setHasSaved}
          />
        )}

        {isEditWeighting && (
          <EditWeighting
            data={{
              weightedDelivery:
                currentAdGroup.creative_weighting_method === 'WEIGHTED',
              creatives: currentAdGroupCreatives,
            }}
            onSubmit={handleWeightingSubmit}
            onClose={handleCloseWeighting}
            isOpen={isEditWeighting}
          />
        )}

        {isEditBid && (
          <ManageBid
            adGroup={currentAdGroup}
            isOpen={isEditBid}
            onClose={handleCloseBid}
            setHasSaved={setHasSaved}
            campaignId={campaignId}
          />
        )}

        {isManageDisplay && (
          <ManageDisplay
            isModal
            isOpen={isManageDisplay}
            campaign={campaign}
            display={currentAdGroup}
            onClose={handleCloseAll}
            setCampaign={setCampaign}
            setHasSaved={setHasSaved}
          />
        )}

        {isControlGroup && (
          <Dialog
            className={classes.dialog}
            maxWidth="xs"
            onClose={handleCloseAll}
            open={isControlGroup}
          >
            <DialogTitle>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Box mr={1}>
                    <InfoOutlinedIcon fontSize="small" color="secondary" />
                  </Box>

                  <Typography variant="h5">Manage Control Group</Typography>
                </Box>

                <IconButton size="small" onClick={handleCloseAll}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent>
              <Typography variant="body2">
                This Ad Group is a Control Group and can not be edited here.
                Changes made to the Test Group will be applied <br />
                to the Control Group.
              </Typography>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseAll} color="secondary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Box>
    </StyledAppHeader>
  );
};

AdGroupsIndexPage.propTypes = {
  history: PropTypes.object,
};

export default AdGroupsIndexPage;
