import React from 'react';
import { Stack, Typography } from '@mui/material';
import { OutOfSyncIcon } from '@v2/components/icons/OutOfSyncIcon';
import CloseIcon from '@mui/icons-material/Close';
import { CloseButton } from './styles';
import { titleProps } from './types';

export const Title = ({ handleClose }: titleProps) => {
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <OutOfSyncIcon />
        <Typography variant="h6">Ad Server Out-of-Sync</Typography>
      </Stack>
      <CloseButton onClick={handleClose} disableRipple>
        <CloseIcon fontSize="large" />
      </CloseButton>
    </>
  );
};
