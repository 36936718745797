export const defaultTitle = 'Campaign Settings';

export const labels = {
  nameDates: {
    label: 'Campaign Name & Dates',
    value: 'name-dates',
  },
  budget: {
    label: 'Campaign Budget',
    value: 'budget',
    disabled: true,
  },
  objective: {
    label: 'Campaign Objective',
    value: 'objective',
  },
  delivery: {
    label: 'Campaign Delivery',
    value: 'delivery',
  },
};

export const nodes = [labels.nameDates, labels.budget, labels.delivery];
