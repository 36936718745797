import { styled } from '@mui/material/styles';
import AppHeader from '@components/AppHeader';

const PREFIX = 'AdGroupsIndexPage';

export const classes = {
  link: `${PREFIX}-link`,
  active: `${PREFIX}-active`,
  actionButton: `${PREFIX}-actionButton`,
  draft: `${PREFIX}-draft`,
  pending: `${PREFIX}-pending`,
  paused: `${PREFIX}-paused`,
  display: `${PREFIX}-display`,
  font: `${PREFIX}-font`,
  scheduled: `${PREFIX}-scheduled`,
  formControl: `${PREFIX}-formControl`,
  menuIcon: `${PREFIX}-menuIcon`,
  stats: `${PREFIX}-stats`,
  tableCell: `${PREFIX}-tableCell`,
  height: `${PREFIX}-height`,
  tableHeader: `${PREFIX}-tableHeader`,
};

export const StyledAppHeader = styled(AppHeader)(
  ({ theme: { typography, spacing } }) => ({
    [`& .${classes.link}`]: {
      textDecoration: 'none',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontWeight: '700',
    },

    [`& .${classes.active}`]: {
      color: '#0fbf84',
      background: 'rgba(122, 220, 187, 0.55)',
      // fontSize: '0.625rem',
    },

    [`& .${classes.actionButton}`]: {
      marginLeft: -8, // aligning based on the visible left edge of the button
    },

    [`& .${classes.draft}`]: {
      color: '#EB9D00',
      background: 'rgba(255, 171, 5, 0.25)',
    },

    [`& .${classes.pending}`]: {
      color: '#EB9D00',
      background: 'rgba(255, 171, 5, 0.25)',
      // fontSize: '0.625rem',
    },

    [`& .${classes.paused}`]: {
      color: '#ff0000',
      background: 'rgba(255, 0, 0, 0.31)',
      // fontSize: '0.625rem',
    },

    [`& .${classes.display}`]: {
      color: '#47505d',
    },

    [`& .${classes.font}`]: {
      // fontSize: '0.75rem',
    },

    [`& .${classes.scheduled}`]: {
      color: '#1dafff',
      background: 'rgba(29, 175, 255, 0.24)',
    },

    [`& .${classes.formControl}`]: {
      margin: spacing(1),
      minWidth: 120,
    },

    [`& .${classes.menuIcon}`]: {
      marginRight: 12,
      fontSize: 18,
    },

    [`& .${classes.stats}`]: {
      fontWeight: '700',
      // fontSize: '0.75rem',
      minHeight: 81,
      height: 81,
    },

    [`& .${classes.tableCell}`]: {
      // fontSize: '0.75rem',
      minWidth: 120,
      minHeight: 81,
      height: 81,
    },

    [`& .${classes.height}`]: {
      // fontSize: '0.75rem',
      minHeight: 81,
      height: 81,
    },

    [`& .${classes.tableHeader}`]: {
      ...typography.h5,
      color: 'rgba(114, 115, 131, 0.8)',
      fontSize: '0.75rem',
      minWidth: 120,
    },
  }),
);
