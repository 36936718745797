import React from 'react';
import { StyledActionButton } from './styles';
import { actionsProps } from './types';

export const Actions = ({ merge, overwrite }: actionsProps) => {
  return (
    <>
      <StyledActionButton variant="text" size="large" onClick={merge}>
        Merge with Ad Server
      </StyledActionButton>
      <StyledActionButton
        variant="contained"
        size="large"
        onClick={overwrite}
      >
        Overwrite Ad Server
      </StyledActionButton>
    </>
  );
};
