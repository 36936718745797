import moment from 'moment-timezone';
import * as yup from 'yup';
import {
  budgetValidation,
  endDateValidation,
  endTimeValidation,
  startDateSimpleValidation,
  startTimeSimpleValidation,
} from '@components/CampaignWizard/validations';
import { Themes } from '@constants/themes';

export const sections = {
  dates: 'dates',
};

const requiredMessage = 'Required field';
export const trackingLockedInfo =
  'This campaign objective will become available once tracking is set up.';

const campaignStartDatePastError = 'Start date must be today or after';

export const fields = {
  name: {
    path: 'name',
    rule: yup.string().required(requiredMessage),
    defaultValue: '',
  },
  advertiserName: {
    path: 'advertiserName',
    defaultValue: '',
  },
  budgetType: {
    path: 'budgetType',
    defaultValue: 'daily_budget',
  },
  lifetimeBudget: {
    path: 'lifetimeBudget',
    defaultValue: '',
    rule: budgetValidation('lifetime_budget'),
  },
  dailyBudget: {
    path: 'dailyBudget',
    defaultValue: '',
    rule: budgetValidation('daily_budget'),
  },
  dailyBudgetCap: {
    path: 'dailyBudgetCap',
    defaultValue: '',
    rule: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === '' ? null : value
      )
      .min(0.01, 'Please enter a daily bugdet cap over one cent.'),
  },
  startDate: {
    path: 'startDate',
    rule: ({ campaign }) =>
      campaign?.active
        ? yup
            .mixed()
            .required(requiredMessage)
            .test(
              'is-date',
              'Start date must be a valid date',
              value => moment.isMoment(value) && value.isValid()
            )
        : startDateSimpleValidation(
            'endDate',
            { min: moment().utc() },
            campaignStartDatePastError
          ),
    defaultValue: moment(new Date()).tz('America/New_York'),
  },
  endDate: {
    path: 'endDate',
    rule: endDateValidation('startDate', 'budgetType'),
    defaultValue: moment(new Date())
      .tz('America/New_York')
      .add(1, 'month')
      .endOf('month'),
    clearable: true,
  },
  startTime: {
    path: 'startTime',
    rule: ({ campaign }) =>
      campaign?.active
        ? yup
            .mixed()
            .required(requiredMessage)
            .test(
              'is-date',
              'Start date must be a valid date',
              value => moment.isMoment(value) && value.isValid()
            )
        : startTimeSimpleValidation(
            'startDate',
            'endDate',
            'endTime',
            { min: moment().utc() },
            campaignStartDatePastError
          ),
    defaultValue: moment(new Date()).tz('America/New_York'),
  },
  endTime: {
    path: 'endTime',
    defaultValue: moment(new Date())
      .tz('America/New_York')
      .add(1, 'month')
      .endOf('month')
      .endOf('day'),
    rule: endTimeValidation('startDate', 'startTime', 'endDate'),
    clearable: true,
  },
  displayPct: {
    path: 'displayPct',
    defaultValue: false,
  },
  objective: {
    path: 'objective',
    defaultValue: null,
    rule: yup.string().required('Required'),
  },
  objectiveGoalEvent: {
    path: 'objectiveGoalEvent',
    defaultValue: null,
    rule: yup
      .number()
      .nullable()
      .when('objective', {
        is: value => objectives[value]?.hasGoalEvent,
        then: yup.number().nullable().required('Required'),
      }),
  },
  objectiveGoalType: {
    path: 'objectiveGoalType',
    defaultValue: '',
    rule: yup
      .string()
      .nullable()
      .when('objective', {
        is: value => objectives[value]?.goalTypes?.length > 0,
        then: yup.string().nullable().required('Required'),
      }),
  },
  objectiveGoalValueMultiplier: {
    path: 'objectiveGoalValueMultiplier',
    defaultValue: null,
    rule: yup
      .number()
      .nullable()
      .when('objectiveGoalType', {
        is: value =>
          goalTypes[value]?.goalValue?.value === goalValueValues.multiplier,
        then: yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : value;
          })
          .nullable()
          .required('Required'),
      }),
  },
  objectiveGoalValueCost: {
    path: 'objectiveGoalValueCost',
    defaultValue: null,
    rule: yup
      .number()
      .nullable()
      .when('objectiveGoalType', {
        is: value =>
          goalTypes[value]?.goalValue?.value === goalValueValues.cost,
        then: yup
          .number()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : value;
          })
          .nullable()
          .required('Required'),
      }),
  },
  advancedOptions: {
    path: 'advancedOptions',
    defaultValue: {
      experimentPlan: undefined,
      experimentType: undefined,
      freqCaps: [],
    },
  },
  active: {
    path: 'active',
    defaultValue: false,
  },
  draft: {
    path: 'draft',
    defaultValue: true,
  },
  budget: {
    path: 'budget',
  },
  status: {
    path: 'status',
  },
  advertiser: {
    path: 'advertiser',
  },
};

export const Copies = {
  [Themes.DEFAULT]: {
    advancedOptionsLabel: 'Advanced campaign delivery options',
    dragToggleText: 'Retarget exposed audience with display ads',
    experimentLabel: 'A/B test:',
    frequencyCapLabel: 'Frequency cap:',
    incrementalityLabel: 'Test incrementality:',
    campaignObjectiveHeader: 'Select a campaign objective',
    objectiveRecommendation: 'Recommended based on your advertiser type',
  },
  [Themes.NBCU]: {
    advancedOptionsLabel: 'Advanced Campaign Delivery Options',
    dragToggleText: 'Retarget Exposed Audience with Display Ads',
    experimentLabel: 'A/B Test:',
    frequencyCapLabel: 'Frequency Cap:',
    incrementalityLabel: 'Test Incrementality:',
    campaignObjectiveHeader: 'Select a Campaign Objective',
    objectiveRecommendation: 'Recommended Based On Your Advertiser Type',
  },
};

const goalTypeValues = {
  cpi: 'cpi',
  cpa: 'cpa',
  roas: 'roas',
  cpv: 'cpv',
};

const goalValueValues = {
  cost: 'cost',
  multiplier: 'multiplier',
};

const goalValues = {
  [goalValueValues.cost]: {
    value: goalValueValues.cost,
    path: fields.objectiveGoalValueCost.path,
    placeHolder: 'Enter Cost',
    symbol: '$',
    decimalScale: 2,
  },
  [goalValueValues.multiplier]: {
    value: goalValueValues.multiplier,
    path: fields.objectiveGoalValueMultiplier.path,
    placeHolder: '#.#',
    symbol: '×',
    decimalScale: 1,
  },
};

export const goalTypes = {
  [goalTypeValues.cpi]: {
    value: goalTypeValues.cpi,
    name: 'Cost per Install',
    goalValue: goalValues.cost,
  },
  [goalTypeValues.cpa]: {
    value: goalTypeValues.cpa,
    name: 'Cost per Action',
    goalValue: goalValues.cost,
  },
  [goalTypeValues.roas]: {
    value: goalTypeValues.roas,
    name: 'ROAS',
    goalValue: goalValues.multiplier,
  },
  [goalTypeValues.cpv]: {
    value: goalTypeValues.cpv,
    name: 'Cost per Visit',
    goalValue: goalValues.cost,
  },
};

export const objectiveValues = {
  appInstalls: 'appInstalls',
  websiteSales: 'websiteSales',
  websiteVisits: 'websiteVisits',
  audienceReach: 'audienceReach',
};

export const objectives = {
  [objectiveValues.appInstalls]: {
    value: objectiveValues.appInstalls,
    label: 'App Installs',
    description: 'Drive new users to download your app from the app store.',
    infoText:
      "Increase the number of times your app is downloaded and installed on users' devices.",
    lockedInfo:
      'This campaign objective will become available once tracking is set up.',
    hasGoalEvent: true,
    goalTypes: [goalTypes.cpi, goalTypes.roas],
  },
  [objectiveValues.websiteSales]: {
    value: objectiveValues.websiteSales,
    label: 'Website Sales',
    description: 'Encourage users to visit your website and make a purchase.',
    infoText:
      'Drive online sales conversions by directing users to your website and encouraging them to buy your products or services.',
    lockedInfo:
      'This campaign objective will become available once tracking is set up.',
    hasGoalEvent: true,
    goalTypes: [goalTypes.cpa, goalTypes.roas],
  },
  [objectiveValues.websiteVisits]: {
    value: objectiveValues.websiteVisits,
    label: 'Website Visits',
    description: 'Increase website traffic and engagement with your brand.',
    infoText:
      'Drive more users to visit specific pages on your website, increasing brand awareness and potential customer engagement.',
    lockedInfo:
      'This campaign objective will become available once tracking is set up.',
    hasGoalEvent: true,
    goalTypes: [goalTypes.cpv],
  },
  [objectiveValues.audienceReach]: {
    value: objectiveValues.audienceReach,
    label: 'Audience Reach',
    description: 'Broaden your audience and increase brand awareness.',
    infoText:
      'Expand the number of unique people who see your ad campaign, maximizing brand exposure to a wider audience.',
    content: '',
    lockedInfo:
      'This campaign objective will become available once tracking is set up.',
    hasGoalEvent: false,
    goalTypes: [],
  },
};
