import {
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  styled,
  type Theme,
} from '@mui/material';

export const CloseButton = styled(IconButton)(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: spacing(3),
  top: spacing(3),
  color: '#435159',
}));

export const StyledList = styled(List)(({ theme: { spacing } }) => ({
  listStyleType: 'disc',
  paddingBottom: 0,
  paddingLeft: spacing(3),
  '& .MuiListItem-root': {
    display: 'list-item',
  },
}));

export const StyledListItem = styled(ListItem)(({ theme: { spacing } }) => ({
  padding: spacing(0.325, 0),
}));

export const StyledPromptContentTypography = styled(Typography)({
  lineHeight: '27px',
  fontSize: '18px',
  fontWeight: '400',
});

export const StyledActionButton = styled(Button)(
  ({ theme: { spacing }, variant }) => ({
    fontSize: '20px',
    padding: spacing(1.625, 4.375), // 13px, 35px
    lineHeight: '28.96px',
    ...(variant === 'text' && { color: '#FF6577' }), // dialog is rendered out of vertical theme scope
  })
);

export const dialogStylesOverride = (theme: Theme) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(5),
    color: '#13171a',
    borderRadius: theme.spacing(2),
    maxWidth: '721px',

    '& .MuiTypography-colorTextSecondary': {
      color: 'inherit',
    },

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(0, 0, 1.75, 0),
    margin: 0,
    fontSize: '20px',
  },
  '& .MuiDialogContent-root': {
    padding: `${theme.spacing(3, 0)} !important`,
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(4.5, 0, 2.5, 0),
  },
  '& .MuiBox-root': {
    margin: `0px !important`,
  },
});
