import { faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment } from 'react';
import {
  SectionPreviewIcon,
  SectionPreviewTypography,
  SectionSubTitle,
  sectionProps,
  useWizardPreview,
} from '../../Wizard';
import { Stack } from '@mui/material';
import { useGroupSectionTitles } from './hooks/useGroupSectionTitles';
import { OutOfSyncHeader } from '@components/Wizard/components/SectionHeaders/OutOfSyncHeader';

const GroupSectionPreview = ({ sectionKey, group }) => {
  const { active, showOutOfSync } = useWizardPreview({
    key: sectionKey,
    group,
  });
  const { title, subtitle } = useGroupSectionTitles({ group });
  return (
    <Stack sx={{ width: '100%' }}>
      {active && showOutOfSync && <OutOfSyncHeader />}
      <Stack direction="row">
        <SectionPreviewIcon isActive={active} icon={faUserGroup} />
        <SectionPreviewTypography isActive={active}>
          {subtitle && <SectionSubTitle>{subtitle}</SectionSubTitle>}
          {title}
        </SectionPreviewTypography>
      </Stack>
    </Stack>
  );
};

GroupSectionPreview.propTypes = {
  ...sectionProps,
};

export default GroupSectionPreview;
