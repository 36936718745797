import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid';
import { Box, Button, MenuItem, Menu } from '@mui/material';
import {
  MoreVert,
  CreateOutlined,
  FileCopyOutlined,
  AttachMoney,
  Delete,
} from '@mui/icons-material';
import { useFlags } from '@components/hooks';
import { useCampaignsTable } from '../CampaignsTableContext';
import { Statuses } from '@components/containers/CampaignsPage/statuses';
import { CampaignArchiveRenderer } from './CampaignArchiveRenderer';
import { CampaignPauseActivateRenderer } from './CampaignPauseActivateRenderer';

export const CampaignActionMenuCellRenderer = (
  params: Partial<GridCellParams>,
) => {
  const { flags } = useFlags();

  const { id, experiment_type } = params.row ?? {};
  let { status } = params.row ?? {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const {
    handleManageBudget,
    handleManageCampaign,
    handleDuplicateCampaign,
    handleArchiveUnarchiveCampaign,
    handlePauseActive,
    handleDraftCampaign,
    handleDeleteDraft,
  } = useCampaignsTable();

  if (!id) {
    return null;
  }

  if (typeof params.value !== 'number') {
    status = parseInt(status, 10);
  }
  const isDraft = status === Statuses.DRAFT;
  const isArchived = status === Statuses.ARCHIVED;
  const canManageCampaign = !experiment_type && !isDraft;
  const canEditBudget = !experiment_type && !isDraft && !isArchived;
  const canPauseActive =
    !isDraft && (status === Statuses.PAUSED || status === Statuses.ACTIVE);
  const canArchive =
    flags.USER_CAN_ARCHIVE &&
    (status === Statuses.PAUSED || status === Statuses.ARCHIVED);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Button
        sx={{ color: 'primary.main' }}
        variant="text"
        size="small"
        id={`action-menu-${id}`}
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </Button>
      <Menu
        id={`action-menu-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `action-menu-${id}`,
        }}
      >
        {isDraft && (
          <MenuItem
            onClick={() => {
              handleDraftCampaign(id);
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            <CreateOutlined fontSize="small" />
            Edit
          </MenuItem>
        )}
        {isDraft && (
          <MenuItem
            sx={{ display: 'flex', gap: 1.5 }}
            onClick={() => {
              handleDeleteDraft(params.row);
              handleClose();
            }}
          >
            <Delete fontSize="small" />
            Delete
          </MenuItem>
        )}

        {canManageCampaign && (
          <MenuItem
            onClick={() => {
              handleManageCampaign(params.row);
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            <CreateOutlined fontSize="small" />
            Manage Campaign
          </MenuItem>
        )}
        {!isDraft && (
          <MenuItem
            onClick={() => {
              handleDuplicateCampaign(params.row);
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            <FileCopyOutlined fontSize="small" /> Duplicate Campaign
          </MenuItem>
        )}
        {canEditBudget && (
          <MenuItem
            onClick={() => {
              handleManageBudget(params.row);
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            <AttachMoney fontSize="small" />
            Edit Budget
          </MenuItem>
        )}
        {canPauseActive && (
          <MenuItem
            onClick={() => {
              handlePauseActive(params.row);
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            <CampaignPauseActivateRenderer status={Number(status)} />
          </MenuItem>
        )}

        {canArchive && (
          <MenuItem
            onClick={() => {
              handleArchiveUnarchiveCampaign(params.row);
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            <CampaignArchiveRenderer status={Number(status)} />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
