import React from 'react';

const ModalContext = React.createContext({
  // eslint-disable-next-line no-unused-vars
  setModal: (config) => {},
});

ModalContext.displayName = 'ModalContext';

export default ModalContext;
