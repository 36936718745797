import React from 'react';
import { WizardContext } from './constants';

import PropTypes from 'prop-types';

export const WizardProvider = ({ children, navigation }) => {
  return (
    <WizardContext.Provider
      value={{
        navigation,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

WizardProvider.propTypes = {
  children: PropTypes.node,
  navigation: PropTypes.object,
  sections: PropTypes.shape({
    statuses: PropTypes.object,
    updateStatus: PropTypes.func,
  }),
};
