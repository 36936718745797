import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material';
import { fromHighlightedContent } from '@v2/components/campaign/Advanced/utils';
import { entries, map, reduce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  toGroups,
  transformToContext,
  transformToGroups,
} from '../utils/transform';
import { getArrayValidation } from './validation';
import { useWizardPane } from '@components/Wizard';

const normalizeField = (data, field) =>
  map(data, i => ({
    ...i,
    [field]: fromHighlightedContent(i?.[field]),
  }));

export const useAdvancedGeneralTable = ({
  data = [],
  byTab = false,
  tab,
  defaultValues,
  selected: selectedData = [],
  selectedFilter,
  dataFieldName,
  workflowName,
  groupByFieldName,
  resolver = () => yupResolver(getArrayValidation()),
  featuredFieldName = 'Featured',
  privateFieldName = 'Private',
  nonTypedFieldName = 'Non-Typed',
  paneKey,
}) => {
  const { updateEntityState } = useWizardPane({
    key: paneKey,
    workflowName,
  });

  const form = useForm({
    criteriaMode: 'all',
    mode: 'all',
    resolver: resolver(),
    values: {
      items: selectedData.length ? selectedData : defaultValues?.items ?? [],
    },
  });

  const {
    watch,
    setValue,
    formState: { isDirty },
  } = form;
  const selected = watch('items');

  const actualSelected = byTab
    ? selected[tab]
    : selectedFilter
    ? selectedFilter(selected)
    : selected;

  const [filter, setFilter] = useState('');

  const passedData = useMemo(() => {
    const selectedIds = actualSelected.map(({ id }) => id);

    const transformed = transformToContext(
      data.filter(({ id }) => !selectedIds.includes(id)),
      filter,
      dataFieldName
    );

    return transformed;
  }, [
    data,
    actualSelected,
    dataFieldName,
    filter,
    groupByFieldName,
    featuredFieldName,
  ]);

  const handleSelect = useCallback(
    items => {
      setValue(
        'items',
        byTab
          ? reduce(
              entries(items),
              (acc, [key, value]) => ({
                ...acc,
                [key]: normalizeField(value, dataFieldName),
              }),
              {}
            )
          : normalizeField(items, dataFieldName),
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      );
    },
    [setValue]
  );

  const transform = useMemo(() => {
    return {
      left: data => {
        if (groupByFieldName) {
          return transformToGroups({
            data,
            generalGroupField: groupByFieldName,
            featuredFieldName,
            privateFieldName,
            nonTypedFieldName,
          });
        }

        return data;
      },
      right: data => toGroups(data, 'type'),
    };
  }, [
    groupByFieldName,
    featuredFieldName,
    privateFieldName,
    nonTypedFieldName,
  ]);

  useEffect(() => {
    setFilter('');
  }, [tab]);

  useEffect(() => {
    updateEntityState({
      dirty: isDirty,
    });
  }, [isDirty, updateEntityState]);

  return {
    transform,
    theme: useTheme(),
    selected: actualSelected,
    data: passedData,
    filter,
    form,
    setSelected: handleSelect,
    setFilter,
  };
};
