import { yupResolver } from '@hookform/resolvers/yup';
import { defaultTo } from 'lodash';
import { useForm } from 'react-hook-form';
import {
  creativesSchema,
  fields,
  weightingRotationOptions,
} from '../constants';
import { useWizardPane } from '@components/Wizard';
import { labels } from '@components/WizardContainers/GroupSection/constants';
import { useEffect } from 'react';

export const useCreativeWrapper = ({
  creatives,
  onSubmit,
  weightingRotation,
}) => {
  const { updateEntityState } = useWizardPane({
    key: labels.creative.value,
    workflowName: labels.creative.label,
  });
  const form = useForm({
    mode: 'all',
    resolver: yupResolver(creativesSchema),
    values: {
      ...Object.values(fields).reduce((acc, { path, defaultValue }) => {
        acc[path] = defaultValue;
        return acc;
      }),
      [fields.weightingRotation.path]:
        weightingRotation === weightingRotationOptions.weighted,
      [fields.creatives.path]: defaultTo(creatives, []),
    },
  });

  const handleSubmit = () => {
    onSubmit({
      creatives: form.getValues(fields.creatives.path),
      weightingRotation: form.getValues(fields.weightingRotation.path),
    });
  };

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    updateEntityState({
      dirty: isDirty,
    });
  }, [isDirty, updateEntityState]);

  return {
    form,
    submit: handleSubmit,
  };
};
