import { useWizardContext } from './useWizardContext';

export const useWizardPane = ({ key, workflowName }) => {
  const { navigation } = useWizardContext();

  const active = navigation.activePane === key;

  const { updateEntityState, hidePane } = navigation;

  return {
    ...navigation,
    hidePane: (options) => {
      hidePane({
        ...options,
        workflowName,
      });
    },
    updateEntityState: state => updateEntityState(key, state),
    active,
  };
};
