import { useAdServerOutOfSyncPromptProps } from '../types';

export const useAdServerOutOfSyncPrompt = ({
  handleClose,
  handleMerge,
  handleOverwrite,
}: useAdServerOutOfSyncPromptProps) => {
  const merge = () => {
    handleMerge();
    handleClose();
  };
  const overwrite = () => {
    handleOverwrite();
    handleClose();
  };
  return {
    merge,
    overwrite,
  };
};
