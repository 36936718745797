import ModalContext from '@providers/ModalContext';
import { isEmpty, isUndefined } from 'lodash';
import React, { useCallback, useContext, useMemo, useRef } from 'react';
import { UnsavedChanges } from '../components';
import { useWizardQuery } from './useWizardQuery';
import { ThemeProvider, useTheme } from '@mui/material';

export const useWizardNavigation = ({ sections = [], sectionNames = [] }) => {
  const theme = useTheme();

  const entitiesStatesRef = useRef(new Map());
  const {
    activeSection,
    activePane,
    goToSection,
    goToCampaign,
    campaignId,
    ...other
  } = useWizardQuery();

  const sectionKeysToNames = useMemo(
    () =>
      sections.reduce(
        (acc, section, index) => ({
          ...acc,
          [section]: sectionNames[index],
        }),
        {}
      ),
    [sections, sectionNames]
  );

  const { setModal } = useContext(ModalContext);

  const currentSectionIndex = useMemo(
    () => sections.findIndex(s => s === activeSection),
    [sections, activeSection]
  );

  const nextSectionIndex = useMemo(
    () =>
      currentSectionIndex === sections.length - 1
        ? undefined
        : currentSectionIndex + 1,
    [currentSectionIndex]
  );

  const prevSectionIndex = useMemo(
    () => (currentSectionIndex === 0 ? undefined : currentSectionIndex - 1),
    [currentSectionIndex]
  );

  const goTo = useCallback(
    (
      { campaignId, sectionId, paneId },
      { checkDirty = true, workflowName = '' } = {}
    ) => {
      const goer = () =>
        campaignId
          ? goToCampaign(campaignId, sectionId, paneId)
          : goToSection(sectionId, paneId);

      if (checkDirty) {
        const { dirty: dirtySection } =
          entitiesStatesRef.current.get(activeSection) || {};

        const { dirty: dirtyPane } =
          entitiesStatesRef.current.get(activePane) || {};

        const actualWorkflowName = isEmpty(workflowName)
          ? sectionKeysToNames[activeSection]
          : workflowName;

        if (
          (sectionId !== activeSection && dirtySection) ||
          (sectionId === activeSection && dirtyPane)
        ) {
          setModal({
            isOpen: true,
            component: () => (
              <ThemeProvider theme={theme}>
                <UnsavedChanges
                  onCancel={() => setModal(null)}
                  workflowName={actualWorkflowName}
                  onConfirm={() => {
                    goer();
                    // This needs to happen last, otherwise the modal will unmount and the onConfirm won't finish
                    setModal(null);
                  }}
                  actionName="leave this section"
                />
              </ThemeProvider>
            ),
          });

          return;
        }
      }

      goer();
    },
    [goToSection, goToCampaign, sectionKeysToNames]
  );

  const hasPrev = useMemo(
    () => !isUndefined(prevSectionIndex) && prevSectionIndex !== -1,
    [prevSectionIndex]
  );

  const hasNext = useMemo(
    () => !isUndefined(nextSectionIndex) && sections[nextSectionIndex],
    [nextSectionIndex]
  );

  const goToNext = useCallback(
    options => {
      if (hasNext) {
        goTo(
          {
            campaignId,
            sectionId: sections[nextSectionIndex],
          },
          options
        );
      }
    },
    [sections, campaignId, hasNext, nextSectionIndex, currentSectionIndex]
  );

  const goToPrev = useCallback(
    options => {
      if (hasPrev) {
        goTo({ campaignId, sectionId: sections[prevSectionIndex] }, options);
      }
    },
    [sections, hasPrev, campaignId, currentSectionIndex, prevSectionIndex]
  );

  const handleUpdateEntityState = useCallback((entityId, status) => {
    entitiesStatesRef.current = new Map(
      entitiesStatesRef.current.set(entityId, status)
    );
  }, []);

  const handeToggleSection = providedSectionId => {
    if (providedSectionId !== activeSection) {
      goTo({
        campaignId,
        sectionId: providedSectionId,
      });

      return;
    }

    goToCampaign(campaignId, null);
  };

  const handleHidePane = options => {
    goTo(
      {
        campaignId,
        sectionId: activeSection,
      },
      options
    );
  };

  return {
    goToNext,
    goToPrev,
    goTo,
    hasNext,
    hasPrev,
    activeSection,
    activePane,
    campaignId,
    nextSectionIndex,
    prevSectionIndex,
    nextSection: sections[nextSectionIndex],
    prevSection: sections[prevSectionIndex],
    hidePane: handleHidePane,
    toggleSection: handeToggleSection,
    updateEntityState: handleUpdateEntityState,
    ...other,
  };
};
