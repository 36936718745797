import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Grid, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';

const StyledMenuItem = styled(MenuItem)(({ theme, type }) => ({
  '&.MuiMenuItem-root': {
    color: type === 'warning' ? theme.palette.red[1] : 'inherit',
  },
}));

export const SubwayMenu = ({ options, disabled, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      item
      justifyContent="flex-end"
      alignItems="center"
      {...props}
      sx={{
        pointerEvents: disabled ? 'none' : 'auto',
        flexShrink: 0,
        ...props.sx,
      }}
    >
      <IconButton
        id="basic-button"
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
        data-testid='ad-group-menu-dots'
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </IconButton>
      <Menu
        id="basic-menu"
        display="flex"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => {
              option.onClick?.();
              handleClose();
            }}
            disabled={option.disabled}
            data-testid={option.label.toLowerCase().replace(/\s+/g, '-')}
            type={option.type}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

SubwayMenu.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      type: PropTypes.string,
    }),
  ),
  sx: PropTypes.object,
};
