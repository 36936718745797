import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
  ListItemText,
  Paper,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AdvertiserContext from '../../AdvertiserContext';
import DeleteDraftCampaign from '../../DeleteDraftCampaign';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ManageBudget from '../../ManageBudget';
import ModalOverlay from '../../ModalOverlay';
import { useAPI } from '../../hooks/api';
import { useLogRocket } from '../../hooks/logRocket';
import { RoutePaths } from '../../../constants';
import {
  Statuses,
  StatusList,
  DefaultStatusKeys,
  StatusNames,
} from './statuses';
import AlertBox from '../../AlertBox';
import { EmbedLookerChart } from '../../EmbedLookerChart';
import { useIntercom } from '../../../helpers/intercomWrapper';
import { getPrimaryOrgId } from '../../../helpers';
import CampaignsTable from './CampaignsTable';
import { classes, StyledAppHeader } from './styles';
import { generatePath } from 'react-router-dom';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import { useGetCampaigns } from '../../hooks/apis/campaigns';
import {
  useAdvertisers,
  useFlags,
  useLoader,
  useQuery,
  useWizardRedirect,
} from '@hooks';
import { useDomain } from '@hooks/domain';
import ArchiveUnarchive from '@components/ArchiveUnarchive';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import CampaignsTableV2 from './CampaignsTableV2';
import { CampaignsTableContextProvider } from './CampaignsTableContext';
import { useCampaigns } from '@hooks/campaigns';

const ManageCampaign = lazy(() => import('../../ManageCampaign'));

const PaperStyled = styled(Paper)(() => ({
  overflow: 'hidden',
}));

const CampaignsPage = props => {
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const urlQuery = useQuery();
  const { currentAdvertiser } = useAdvertisers();
  const { flags, Flags } = useFlags();
  const { update: updateCampaignCache, remove: removeFromCampaignCache } =
    useGetCampaigns();
  const { updateCampaign } = useCampaigns();
  const isVerticalCampaignFlowEnabled =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const isArchivingEnabled = flags.USER_CAN_ARCHIVE;
  const { campaignsV2Table } = useLDFlags();

  const { useGet } = useAPI();
  const { isLoading: isPageLoading } = useLoader();

  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setAlertOpen] = useState(true);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [isCampaign, setIsCampaign] = useState(false);
  const [isDupeCampaign, setIsDupeCampaign] = useState(false);
  const [isEditBudget, setIsEditBudget] = useState(false);
  const [isDeleteDraft, setIsDeleteDraft] = useState(false);
  const [archiveState, setArchiveState] = useState(null);
  const [statuses, setStatuses] = useState(DefaultStatusKeys);
  const regularStatuses = StatusList.filter(
    status => status.value !== Statuses.ARCHIVED
  );
  const regularStatusValues = regularStatuses.map(status => status.value);
  const archivedStatus = StatusList.find(
    status => status.value === Statuses.ARCHIVED
  );
  const { tagUser } = useLogRocket();
  const { showArticle } = useIntercom();
  const { editCampaign } = useWizardRedirect();

  useEffect(() => {
    // Tag user sso id, name and email in Log Rocket
    tagUser();
    getAlerts();
  }, []);

  const handleManageBudget = campaign => {
    setCurrentCampaign(campaign);
    setIsEditBudget(true);
  };

  const handleDeleteDraft = campaign => {
    setCurrentCampaign(campaign);
    setIsDeleteDraft(true);
  };

  const handleDuplicateCampaign = async campaign => {
    setCurrentCampaign(campaign);
    setIsDupeCampaign(true);
    setIsCampaign(true);
  };

  const handleDraftCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign });
    }

    props.history.push(
      generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, { id: campaign })
    );
  };

  const handleManageCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign.id });
    }

    setCurrentCampaign(campaign);
    setIsCampaign(true);
  };

  const handlePauseActive = async campaign => {
    const response = await updateCampaign(campaign.id, { active: !campaign.active });
    return updateCampaignCache(response?.data);
  };

  const handleSaveCampaign = campaign => {
    if (campaign) {
      updateCampaignCache(campaign);
    }
    setIsDupeCampaign(false);
  };

  const handleDeleteCampaignComplete = campaign => {
    removeFromCampaignCache(campaign.id);
  };

  const handleArchiveUnarchiveCampaign = campaign => {
    const isArchiving = campaign.status === Statuses.PAUSED;
    setArchiveState(isArchiving ? 'archiving' : 'unarchiving');
    setCurrentCampaign(campaign);
  };

  const handleStatusesChange = event => {
    const { value } = event.target;
    const nextStatuses = typeof value === 'string' ? value.split(',') : value;

    // If "Select All" is clicked
    if (nextStatuses[nextStatuses.length - 1] === 'all') {
      // If all regular statuses are currently selected, clear them
      // Otherwise, select all regular statuses
      const hasAllRegularStatuses = regularStatusValues.every(status =>
        statuses.includes(status)
      );

      // Keep the archived status selection
      const keepArchivedStatus = statuses.includes(Statuses.ARCHIVED)
        ? [Statuses.ARCHIVED]
        : [];

      setStatuses(
        hasAllRegularStatuses
          ? keepArchivedStatus
          : [...regularStatusValues, ...keepArchivedStatus]
      );
      return;
    }

    setStatuses(nextStatuses);
  };

  const handleCloseDeleteDraft = () => {
    setIsDeleteDraft(false);
  };

  const handleCloseBudget = () => {
    setIsEditBudget(false);
    setCurrentCampaign(null);
  };

  const handleCloseArchiveUnarchive = () => {
    setArchiveState(null);
    setCurrentCampaign(null);
  };

  useEffect(() => {
    if (isVerticalCampaignFlowEnabled) {
      urlQuery.set('org', getPrimaryOrgId(currentAdvertiser.primary_org));
      urlQuery.set('adAccount', currentAdvertiser?.id);
    }
  }, [
    isVerticalCampaignFlowEnabled,
    urlQuery,
    currentAdvertiser?.id,
    currentAdvertiser?.primary_org,
  ]);

  async function getAlerts() {
    const alerts = await useGet('/alerts');
    setAlert(alerts.activeAlert !== null ? alerts : null);
  }

  const goLiveMessage = {
    title: 'NOTICE',
    message:
      'This Ad Account needs to be associated with a valid Billing Account before any Campaigns will go live! ',
    type: 'MAJOR',
    closeable: false,
    isAlertOpen: true,
  };

  const campaignsTableProps = {
    statuses,
    handleManageBudget,
    handleDeleteDraft,
    handleDuplicateCampaign,
    handleDraftCampaign,
    handleManageCampaign,
    handleArchiveUnarchiveCampaign,
    handlePauseActive,
    urlQuery,
  };

  return (
    <StyledAppHeader history={props.history}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {alert !== null && (
            <Grid item xs={12}>
              <AlertBox
                {...alert.activeAlert}
                isAlertOpen={isAlertOpen}
                closeAlert={setAlertOpen}
              />
            </Grid>
          )}
          {!adContext?.billing_account_is_valid && (
            <Grid item xs={12}>
              <AlertBox
                {...goLiveMessage}
                isAlertOpen={isAlertOpen}
                closeAlert={setAlertOpen}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => showArticle('8516335')}
                  >
                    Click Here for Help!
                  </Button>
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PaperStyled>
              <EmbedLookerChart dashboard="CAMPAIGN" />
            </PaperStyled>
          </Grid>
        </Grid>
      </Container>

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius="20px"
        data-testid="campaigns-card"
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              variant="h6"
              component="div"
              data-testid="active-campaigns-header"
            >
              Active Campaigns
            </Typography>
          </Grid>

          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="status-multiple-checkbox-label">
                Status
              </InputLabel>
              <Select
                variant="standard"
                multiple
                color="secondary"
                id="status-multiple-checkbox"
                labelId="status-multiple-checkbox-label"
                label="Status"
                value={statuses}
                onChange={handleStatusesChange}
                renderValue={selected => {
                  const hasAllRegularStatuses = regularStatusValues.every(
                    value => selected.includes(value)
                  );
                  const hasArchivedStatus = selected.includes(
                    Statuses.ARCHIVED
                  );
                  const selectedValues = selected
                    .map(item => StatusNames[item])
                    .join(', ');

                  const displayText = hasAllRegularStatuses
                    ? hasArchivedStatus
                      ? 'All, Archived'
                      : 'All'
                    : selectedValues;

                  return `Showing [${displayText}] status`;
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 48 * 6.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                <MenuItem value="all">
                  <Checkbox
                    checked={regularStatuses.every(status =>
                      statuses.includes(status.value)
                    )}
                    indeterminate={
                      regularStatuses.some(status =>
                        statuses.includes(status.value)
                      ) &&
                      !regularStatuses.every(status =>
                        statuses.includes(status.value)
                      )
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {regularStatuses.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    <Checkbox checked={statuses.includes(value)} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
                {isArchivingEnabled && (
                  <Box>
                    <Divider
                      sx={{
                        borderBottomWidth: '2px',
                        borderBottomColor: 'grey.main',
                        marginY: '8px',
                      }}
                      flexItem
                    />
                    <MenuItem
                      key={archivedStatus.value}
                      value={archivedStatus.value}
                      onClick={() => {
                        const nextStatuses = statuses.includes(
                          archivedStatus.value
                        )
                          ? statuses.filter(
                              status => status !== archivedStatus.value
                            )
                          : [...statuses, archivedStatus.value];
                        setStatuses(nextStatuses);
                      }}
                      data-testid="archived-status"
                    >
                      <Checkbox
                        checked={statuses.includes(archivedStatus.value)}
                      />
                      <ListItemText primary={archivedStatus.name} />
                    </MenuItem>
                  </Box>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <ErrorBoundary fallback={<h2>Could not fetch campaigns.</h2>}>
          <Suspense>
            {campaignsV2Table ? (
              <CampaignsTableContextProvider {...campaignsTableProps}>
                <CampaignsTableV2 />
              </CampaignsTableContextProvider>
            ) : (
              <CampaignsTable {...campaignsTableProps} />
            )}
          </Suspense>
        </ErrorBoundary>

        {isEditBudget && (
          <ManageBudget
            isModal
            isOpen={isEditBudget}
            currentCampaign={currentCampaign}
            onClose={handleCloseBudget}
            setHasSaved={handleSaveCampaign}
          />
        )}

        {isCampaign && (
          <ModalOverlay
            className={classes.modal}
            isOpen={isCampaign}
            onClose={() => {
              setIsCampaign(false);
              setIsDupeCampaign(false);
            }}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <ManageCampaign
                showTestIncrementality={
                  domain.default ||
                  (domain.peacock && !!currentCampaign.experiment_type)
                }
                showType={domain.default}
                isDupeCampaign={isDupeCampaign}
                setHasSaved={handleSaveCampaign}
                setIsCampaign={setIsCampaign}
                setIsDupeCampaign={setIsDupeCampaign}
                currentCampaign={currentCampaign}
              />
            </Suspense>
          </ModalOverlay>
        )}

        {isDeleteDraft && (
          <DeleteDraftCampaign
            isModal
            isOpen={isDeleteDraft}
            campaign={currentCampaign}
            onClose={handleCloseDeleteDraft}
            onDelete={handleDeleteCampaignComplete}
          />
        )}

        {isPageLoading && (
          <Box width="100%" height="100%" className={classes.pageLoader}>
            <CircularProgress color="secondary" />
          </Box>
        )}

        {archiveState && (
          <ArchiveUnarchive
            isOpen={!!archiveState}
            campaign={currentCampaign}
            onClose={handleCloseArchiveUnarchive}
            isArchiving={archiveState === 'archiving'}
          />
        )}
      </Box>
    </StyledAppHeader>
  );
};

CampaignsPage.propTypes = {
  history: PropTypes.object,
};

export default CampaignsPage;
