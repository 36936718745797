import React from 'react';
import {
  GridColDef,
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
} from '@mui/x-data-grid';
import { usePinnedColumns } from './hooks/usePinnedColumns';
import LazyLoadingObserver from '@components/LazyLoadingObserver';

export enum PinnedPosition {
  NONE = 0,
  LEFT = 1,
  RIGHT = 2,
  VIRTUAL = 3,
}

export type DataGridColumn = GridColDef & {
  pinned?: PinnedPosition;
};

export type DataGridProps = MuiDataGridProps & {
  columns: DataGridColumn[];
  hasHeader?: boolean;
};

export const DataGrid = (props: DataGridProps) => {
  const {
    columns,
    checkboxSelection,
    hasHeader = false,
  } = props;
  if (!columns) {
    return null;
  }

  const { adjustedColumns, pinnedStyles, hasPinnedColumns } =
    usePinnedColumns({ columns, checkboxSelection });

  return (
    <>
      <MuiDataGrid
        {...props}
        disableVirtualization={hasPinnedColumns} // Disable virtualization if there are pinned columns or else the pinned columns will not render
        columns={adjustedColumns}
        sx={{
          '.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(even)':
            {
              backgroundColor: 'rgba(250, 250, 250, 1)',
            },
          ...pinnedStyles,
          ...(hasHeader && {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
          }),
        }}
      />
    </>
  );
};
