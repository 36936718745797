import { useEffect, useRef, useState } from "react";
import { Filters, useLookerFiltersContext } from "@providers/LookerFiltersContext";
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { LookerEmbedDashboard, LookerEmbedEvent } from "@looker/embed-sdk";

const DEFAULT_FILTERS = {
  'Outcome': 'No Tracked Outcome',
  'Date Filter': 'This Month',
  'Attribution Model': 'linear^_attribution',
  'Attribution Model Selector': 'linear^_attribution',
  'Attribution Window': "AdvertiserSpecified",
  'Attribution Window Selector': "AdvertiserSpecified",
  'Campaign': '',
  'Campaign Name': ''
}

const useLookerFilters = () => {
  const dashboardRef = useRef<null | LookerEmbedDashboard>(null);
  const [dashboardLoaded, setDashboardLoaded] = useState(false);
  const { reportsStickyFilters } = useLDFlags();
  const { filters, setFilters } = useLookerFiltersContext()

  useEffect(() => {
    if (dashboardLoaded && reportsStickyFilters) {
      // Sometimes the Outcome filter is different between dashboards.
      // This prevents the dashboards from being out of sync with each other
      // and causing a infinite loop or overwriting each other.
      dashboardRef.current?.updateFilters(DEFAULT_FILTERS);
      dashboardRef.current?.run();
    }
  }, [dashboardLoaded])

  useEffect(() => {
    if (dashboardRef.current && reportsStickyFilters) {
      dashboardRef.current.updateFilters(filters);
      dashboardRef.current.run();
    }
  }, [filters])

  const setupDashboard = (dashboard: LookerEmbedDashboard) => {
    dashboardRef.current = dashboard
    dashboard.run()
    setDashboardLoaded(true)
  }

  const handleFiltersChanged = (event: LookerEmbedEvent) => {
    if (setFilters) {
      setFilters((prev: Filters) => {
        const { dashboard: { dashboard_filters } } = event
        const newFilters = ({
          ...prev,
        })

        if (dashboard_filters['Date Filter']) {
          newFilters['Date Filter'] = dashboard_filters['Date Filter']
        }

        if (dashboard_filters['Outcome']) {
          newFilters['Outcome'] = dashboard_filters['Outcome']
        }

        const newAtttributionModel =
          dashboard_filters['Attribution Model Selector'] ||
          dashboard_filters['Attribution Model']

        if (newAtttributionModel !== undefined) {
          newFilters['Attribution Model Selector'] = newAtttributionModel;
          newFilters['Attribution Model'] = newAtttributionModel;
        }

        const newAtttributionWindow =
          dashboard_filters['Attribution Window Selector'] ||
          dashboard_filters['Attribution Window']

        if (newAtttributionWindow !== undefined) {
          newFilters['Attribution Window Selector'] = newAtttributionWindow;
          newFilters['Attribution Window'] = newAtttributionWindow;
        }

        const newCampaign =
          dashboard_filters.Campaign ||
          dashboard_filters['Campaign Name']

        if (newCampaign !== undefined) {
          newFilters.Campaign = newCampaign;
          newFilters['Campaign Name'] = newCampaign;
        }

        return newFilters
      });
    }
  }

  return { setupDashboard, handleFiltersChanged }
};

export default useLookerFilters;
