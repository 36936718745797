import { map } from 'lodash';
import { fields } from './Manage/constants';

const pixelField = fields.impression_tracking_pixel.path;

export const transformCreativeToImpressionPixelsArray = creative => ({
  ...creative,
  [pixelField]: map(
    Array.isArray(creative[pixelField])
      ? creative[pixelField]
      : JSON.parse(creative[pixelField] ?? '[]').filter(tp => tp),
    pixel => ({
      value: pixel,
    })
  ),
});

export const transformFromImpressionPixelsArray = (creatives = []) =>
  map(creatives, creative => ({
    ...creative,
    [pixelField]: JSON.stringify(
      map(creative[pixelField], 'value')
        .map(v => v.trim())
        .filter(tp => tp)
    ),
  }));
