import { defaultAdGroupName } from '@apis/adGroups';
import { sections } from '@components/Wizard';
import { uniqueId } from 'lodash';
import moment from 'moment';
import { createContext } from 'react';

export const sectionGetter = {
  group: id => String(id),
  staticGroup: id => `static-${id}`,
  review: () => sections.review,
  campaign: () => sections.campaign,
};

export const getEmptyGroup = ({
  name = defaultAdGroupName(),
  id = uniqueId(),
  campaign,
} = {}) => ({
  name,
  status: 'draft',
  id,
  temporary: true,
  campaign: Number(campaign?.id),
  start_date: campaign?.start_date,
  end_date: campaign?.end_date,
});

export const WizardPageContext = createContext({});
export const getEmptyRetargetingGroup = campaign => {
  const timestamp = moment().format('MMMM Do, h:mm a');
  return {
    campaign: Number(campaign?.id ?? uniqueId()),
    creatives: [],
    end_date: campaign?.end_date,
    id: 1,
    isDisplay: true,
    name: `${timestamp} Ad Group - Display Retargeting`,
    start_date: campaign?.start_date,
    temporary: true,
  };
};

export const bwSyncParams = {
  sync: 1,
};
