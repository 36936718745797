import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import { CampaignForm } from '@v2/components/forms/CampaignForm';

import { useWizardContext } from '@components/Wizard';
import { SCROLL_DELAY } from '../../../constants';
import { useScrollTo } from '../../../hooks/useScrollTo';
import { labels } from '../../constants';

function CampaignInfoPane({
  sectionKey,
  campaignId,
  onRetargetDisplayToggleChange,
  campaignStaticDisplayAdGroups,
  onSubmit,
}) {

  const ref = useScrollTo(SCROLL_DELAY);
  const {
    navigation: { goTo },
  } = useWizardContext();

  const handleOpenAdvancedCampaignDeliveryOptions = useCallback(() => {
    goTo({
      campaignId,
      sectionId: sectionKey,
      paneId: labels.delivery.value,
    });
  }, [goTo, campaignId, sectionKey]);

  return (
    <CampaignForm
      ref={ref}
      onRetargetDisplayToggleChange={onRetargetDisplayToggleChange}
      campaignStaticDisplayAdGroups={campaignStaticDisplayAdGroups}
      onSubmit={onSubmit}
      campaignId={campaignId}
      onAdvancedCampaignDeliveryOptionsClick={
        handleOpenAdvancedCampaignDeliveryOptions
      }
    />
  );
}

CampaignInfoPane.propTypes = {
  onRetargetDisplayToggleChange: PropTypes.func,
  campaignStaticDisplayAdGroups: PropTypes.array,
  campaignId: PropTypes.number,
  paneKey: PropTypes.string.isRequired,
  sectionKey: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default memo(CampaignInfoPane);
