import React from 'react';
import { Stack, StackProps } from '@mui/material';
import { SectionHeaderStyled } from './styles';

export const SectionHeader = ({ children, ...props }: StackProps) => (
  <SectionHeaderStyled {...props}>
    <Stack alignItems="center" direction="row" spacing={1.5} flex={1}>
      {children}
    </Stack>
  </SectionHeaderStyled>
);
