import React, { memo } from 'react';
import moment from 'moment-timezone';
import { merge, mergeWith } from 'lodash';
import { Theme } from '@mui/material/styles/createTheme.d';
import { axisClasses } from '@mui/x-charts';
import {
  LineChart as DefaultLineChart,
  lineElementClasses
} from '@mui/x-charts/LineChart';
import { LineChartProps } from '@mui/x-charts/LineChart/LineChart.d';

export const defaultMargin = {
  top: 30,
  bottom: 20,
  left: 30,
  right: 0,
};

export const defaultSeries = {
  type: 'line',
  showMark: false,
  area: true,
  baseline: 'min',
};

export const defaultXAxis = {
  scaleType: 'time',
  tickNumber: 12,
  min: 'auto',
  max: 'auto',
  tickMinStep: 1000 * 60 * 5, // 5 minutes in milliseconds
  valueFormatter: (value: number | Date) => moment(new Date(value)).format('h:mm A'),
};

export const defaultYAxis = {
  tickNumber: 5,
  disableTicks: true,
  valueFormatter: (value: number) => {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);

      return formattedValue.endsWith('.0')
        ? `${Math.round(value / 1000)}K`
        : `${formattedValue}K`;
    }

    return `${value}`;
  },
};

export const defaultSx = (theme: Theme) => ({
  '& .MuiChartsGrid-line': {
    stroke: theme.palette.grey[10],
  },
  [`& .${lineElementClasses.root}`]: {
    strokeWidth: 4
  },
  [`.${axisClasses.root}`]: {
    [`.${axisClasses.tick}, .${axisClasses.line}`]: {
      stroke: theme.palette.grey[8],
      strokeWidth: 1,
    },
    [`.${axisClasses.tickLabel}`]: {
      fontSize: 14,
      fontWeight: 700,
      fill: theme.palette.grey[4]
    },
  },
});

const LineChart = (props: LineChartProps) => {
  const gradientStyles = props.series.reduce<Record<string, React.CSSProperties>>((acc, series) => {
    if (series.id && series.color) {
      const seriesId = String(series.id);

      acc[`& .MuiAreaElement-series-${seriesId}`] = {
        fill: `url('#${seriesId}Gradient')`
      };
    }

    return acc;
  }, {});

  const defs = props.series.reduce<React.ReactNode[]>((acc, series) => {
    if (series.id && series.color) {
      acc.push(
        <linearGradient
          key={series.id}
          id={`${series.id}Gradient`}
          gradientTransform="rotate(90)"
        >
          <stop offset='1.1%' stopColor={`${series.color}33`} />
          <stop offset='73.23%' stopColor={`${series.color}01`} />
        </linearGradient>
      );
    }

    return acc;
  }, []);

  return (
    <DefaultLineChart
      height={300}
      disableAxisListener
      grid={{ horizontal: true }}
      {...props}
      series={mergeWith(
        [],
        props.series,
        (_objValue, srcValue) => merge({}, defaultSeries, srcValue)
      )}
      xAxis={mergeWith(
        [],
        props.xAxis,
        (_objValue, srcValue) => merge({}, defaultXAxis, srcValue)
      )}
      yAxis={mergeWith(
        [],
        props.yAxis,
        (_objValue, srcValue) => merge({}, defaultYAxis, srcValue)
      )}
      margin={merge({}, defaultMargin, props.margin)}
      sx={theme => merge({ ...gradientStyles }, defaultSx(theme), props.sx)}
    >
      <defs>
        {defs}
      </defs>
      {props.children}
    </DefaultLineChart>
  );
};

export default memo(LineChart);
