import PropTypes from 'prop-types';

export const sectionProps = {
  sectionName: PropTypes.string,
  sectionKey: PropTypes.string,
  dirty: PropTypes.bool,
  adGroup: PropTypes.object,
  campaignId: PropTypes.object,
  localAssets: PropTypes.any, // it's a Map<number, string> but PropTypes doesn't support that
};

export const wizardProps = {
  sections: PropTypes.arrayOf(PropTypes.string),
  campaign: PropTypes.object,
  groups: PropTypes.array,
};

export const subwayProps = {
  ...sectionProps,
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  menuDisabled: PropTypes.bool,
};
