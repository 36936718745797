import React from 'react';
import { Accordion, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { useWizardSection } from '../hooks/useWizardSection';

const SectionStyled = styled(Accordion)(({ theme }) => ({
  padding: theme.spacing(5), //' 40 / 8 = 5,
  scrollMarginTop: theme.spacing(14), // consistent with vertical timeline
  backgroundColor: theme.palette.common.white,
  '&.MuiPaper-rounded': {
    borderRadius: 20,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'auto',
  },
  '&:not(:last-child)': {
    marginBottom: theme.spacing(3.125), // 25 / 8 = 3.125
  },
  '.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: theme.spacing(3), // 24 / 8 = 3
  },
  '.MuiAccordionSummary-content.Mui-expanded': {
    marginTop: theme.spacing(1.5), // 12 / 8 = 1.5
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.common.white,
  },
}));

export const Section = ({ sectionKey, children, ...props }) => {
  const { active, toggleSection } = useWizardSection({
    key: sectionKey,
  });

  return (
    <SectionStyled
      {...props}
      slotProps={{
        transition: {
          unmountOnExit: true,
          mountOnEnter: false,
        },
      }}
      expanded={active}
      elevation={3}
      onChange={() => toggleSection(sectionKey)}
    >
      {children}
    </SectionStyled>
  );
};

Section.propTypes = {
  ...Accordion.propTypes,
  sectionKey: PropTypes.string,
  sectionName: PropTypes.string,
};
