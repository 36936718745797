import React, { useEffect, useMemo, useState } from 'react';
import { Router, matchPath } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { createStore, StateMachineProvider } from 'little-state-machine';
import LogRocket from 'logrocket';
import { HelmetProvider } from 'react-helmet-async';
import { SWRConfig } from 'swr';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/react';

import { AdvertisersContextProvider } from './AdvertisersContext';
import { RBACContextProvider } from './RBACContext';
import { PingContextProvider } from './PingContext';
import { AuthContextProvider } from './AuthContext';
import { UserContextProvider } from './UserContext';
import { BWSyncContextProvider } from './BWSyncContext';
import { CurrentSessionContextProvider } from './CurrentSessionContext';
import { OrgContextProvider } from './OrgContext';
import { FlagsContextProvider } from './FlagsContext';
import { SnackbarProvider } from './SnackbarContext';
import ModalContext from './ModalContext';
import DialogContext from '../providers/DialogContext';
import SSOUserContext from '../components/SSOUserContext';
import AdvertiserContext from '../components/AdvertiserContext';
import { defaultTheme, nbcuTheme } from '../themes';
import { IntercomProvider } from '../helpers/intercomWrapper';
import { getTheme } from '../helpers';
import { createRoutes } from '../routes';
import { Themes } from '../constants';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { localStorageProvider, logger } from '../swr';
import { useDomain } from '@components/hooks/domain';

// Create new Okta instance
const oktaAuth = new OktaAuth({
  pkce: false,
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}/oauth2/default`,
  redirectUri: `${window.location.origin}/login/callback`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
});

const history = createBrowserHistory();
const routes = createRoutes({});

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.25,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      new RegExp(`^${
        process.env.API_URL
          .replace(/\./g, '\\.')
          .replace(/\//g, '\\/')
      }`),
    ],
    integrations: [
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV5BrowserTracingIntegration({
        history,
        routes,
        matchPath,
      }),
    ],
  });

  LogRocket.getSessionURL(sessionURL => {
    Sentry.withScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

createStore({
  data: {
    inventoryStep: {
      deviceOverlayModal: {
        deviceTypes: [],
        operatingSystems: [],
      },
    }
  }
});

export const AppProvider = (({ children }) => {
  const [modal, setModal] = useState(null);
  const [dialog, setDialog] = useState(null);
  const [intercomEnv, setIntercomEnv] = useState('production');
  const [ssoUser, setSSOUser] = useState(null);
  const domain = useDomain();
  const [advertiser, setAdvertiser] = useState({
    id: null,
    name: '',
    token: null,
    theme: getTheme({
      isNbcuSubdomain: window.location.hostname.startsWith('peacock')
    }),
    cost_model: null,
    updateAdvertiser: data => {
      setAdvertiser(advertiser => ({
        ...advertiser,
        ...data,
      }))
    }
  });

  const theme = useMemo(() => advertiser.theme === Themes.DEFAULT ? defaultTheme : nbcuTheme, [advertiser.theme]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const intercomEnvValue = urlParams.get('intercom-env');

    if (intercomEnvValue) {
      setIntercomEnv(intercomEnvValue);
    }

  }, []);

  const isPeacockDomain = domain.peacock;
  const isStaging = intercomEnv === 'staging';

  let appId;
  let environment;

  if (isPeacockDomain) {
    console.log('Using NBCU domain');
    if (isStaging) {
      console.log('Environment: NBCU Staging');
      appId =  process.env.INTERCOM_APP_ID_NBCU_QA_INTEGRATION;
      environment = 'NBCU QA Integration';
    } else {
      console.log('Environment: NBCU Production');
      appId =  process.env.INTERCOM_APP_ID_NBCU_PROD;
      environment = 'NBCU Production';
    }
  } else {
    console.log('Using non-NBCU domain');
    if (isStaging) {
      console.log('Environment: Staging');
      appId = process.env.INTERCOM_APP_ID_STAGING;
      environment = 'Staging';
    } else {
      console.log('Environment: Production');
      appId = process.env.INTERCOM_APP_ID_PROD;
      environment = 'Production';
    }
  }

  console.log(`Intercom App ID set for ${environment} environment`);

  return (
    <HelmetProvider>
      <StateMachineProvider>
        <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
          <Router history={history}>
            <Security oktaAuth={oktaAuth}>
              <PingContextProvider>
                <AuthContextProvider>
                  <CurrentSessionContextProvider>
                    <SWRConfig
                      value={{
                        provider: localStorageProvider,
                        refreshInterval: 30 * 60 * 1000, // 30 minutes
                        suspense: false,
                        use: [logger],
                      }}
                    >
                      <FlagsContextProvider>
                        <ThemeProvider theme={theme}>
                          <CssBaseline />
                          <SnackbarProvider>
                            <ModalContext.Provider value={{ modal, setModal }}>
                              <DialogContext.Provider value={{ dialog, setDialog }}>
                                <UserContextProvider>
                                  <AdvertisersContextProvider>
                                    <AdvertiserContext.Provider value={advertiser}>
                                      <OrgContextProvider>
                                        <BWSyncContextProvider>
                                          <SSOUserContext.Provider
                                            value={{ user: ssoUser, setUser: setSSOUser }}
                                          >
                                            <IntercomProvider appId={appId}>
                                              <RBACContextProvider>
                                                {typeof children === 'function' ? children({ modal, dialog }) : children}
                                              </RBACContextProvider>
                                            </IntercomProvider>
                                          </SSOUserContext.Provider>
                                        </BWSyncContextProvider>
                                      </OrgContextProvider>
                                    </AdvertiserContext.Provider>
                                  </AdvertisersContextProvider>
                              </UserContextProvider></DialogContext.Provider>
                            </ModalContext.Provider>
                          </SnackbarProvider>
                        </ThemeProvider>
                      </FlagsContextProvider>
                    </SWRConfig>
                  </CurrentSessionContextProvider>
                </AuthContextProvider>
              </PingContextProvider>
            </Security>
          </Router>
        </LocalizationProvider>
      </StateMachineProvider>
    </HelmetProvider>
  );
});

AppProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
