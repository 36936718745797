import React, { useContext, useMemo, useState } from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import SortableTableHead from '@components/ui/SortableTableHead';
import moment from 'moment-timezone';
import { useSort } from '@hooks/sort';
import { classes } from './styles';
import { useGetTableHeaders } from '@components/containers/AdGroupsIndexPage/hooks/useGetTableHeaders';
import { Campaign } from 'types/campaign';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { LineItem } from 'types/lineitem';
import { AdGroupsTableLineItemRow } from './AdGroupsTableLineItemRow';
import { AdGroupsTableStaticDisplayRow } from './AdGroupsTableStaticDisplayRow';
import { useCampaignAdGroupsPage } from '@components/hooks/apis/adGroups';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { getStatus } from './getStatus';
import RealtimeDeliveryModal from '@components/containers/AdGroupsIndexPage/renderers/RealtimeDeliveryModal';
import ModalContext from '@providers/ModalContext';
import { useFlags } from '@hooks/flags';

export interface AdGroupsTableProps {
  campaign: Campaign;
  campaignId: number;
  statuses: number[];
  handleManageDisplay: (display: LineItem) => void;
  handleManageAdGroup: (adGroup: LineItem) => void;
  handleDuplicateAdGroup: (adGroup: LineItem) => void;
  handleOpenWeighting: (adGroup: LineItem) => void;
  handleIsDuplicatable: (adGroup: LineItem) => boolean;
  handleOpenBid: (adGroup: LineItem) => void;
}

export const renderStatus = (status: number | boolean, type: string) => {
  if (status) {
    return (
      <>
        <PauseCircleOutlineOutlinedIcon
          fontSize="small"
          className={classes.menuIcon}
        />
        Pause {type}
      </>
    );
  }

  return (
    <>
      <PlayCircleOutlineIcon fontSize="small" className={classes.menuIcon} />
      Activate {type}
    </>
  );
};

export const formatEndDate = (endDate: string | null) => {
  if (endDate && Date.parse(endDate)) {
    return moment(endDate).format('l');
  }
  return 'No End Date';
};

export const AdGroupsTable = ({
  campaign,
  campaignId,
  statuses,
  handleDuplicateAdGroup,
  handleOpenWeighting,
  handleOpenBid,
  handleIsDuplicatable,
  ...rest
}: AdGroupsTableProps) => {
  const { flags, Flags } = useFlags();
  const { order, orderBy, handleRequestSort, getComparator, stableSort } =
    useSort();
  const { v2AdgroupsLineItems } = useLDFlags();

  const isVerticalCampaignFlowEnabled =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];

  const [currentAnchorEl, setCurrentAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const [targetMenu, setTargetMenu] = useState<number | null>(null);
  const headCells = useGetTableHeaders();
  const { setModal } = useContext(ModalContext);

  const { items, update } = useCampaignAdGroupsPage(campaignId, {
    v1: !v2AdgroupsLineItems,
  });
  const { items: staticItems } = useCampaignAdGroupsPage(
    campaignId,
    {
      v1: true,
    },
    '/static_display_lineitems/',
  );

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    rowId: number,
  ) => {
    setCurrentAnchorEl(event.currentTarget);
    setTargetMenu(rowId);
  };

  const handleCloseMenu = () => {
    setCurrentAnchorEl(null);
    setTargetMenu(null);
  };

  const handlePauseActive = (adGroup: LineItem) => {
    update({ id: adGroup.id, active: !adGroup.active });
  };

  const handleOpenRealTimeModal = (
    event: React.MouseEvent<HTMLElement>,
    adGroup: LineItem,
  ) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      component: RealtimeDeliveryModal,
      data: {
        id: adGroup.beeswax_lid,
        description: `${adGroup.name} (${adGroup.id})`,
      },
    });
  };

  const handleManageDisplay = (display: LineItem) => {
    if (isVerticalCampaignFlowEnabled) {
      handleCloseMenu();
    }
    return rest.handleManageDisplay(display);
  };

  const handleManageAdGroup = (adGroup: LineItem) => {
    if (isVerticalCampaignFlowEnabled) {
      handleCloseMenu();
    }
    return rest.handleManageAdGroup(adGroup);
  };

  const sortedAdGroups = useMemo(() => {
    return stableSort(
      [...items, ...staticItems] as any[],
      getComparator(order, orderBy),
    )
      .map(lineItem => {
        return {
          ...lineItem,
          status: getStatus(
            lineItem.active,
            lineItem.pending_active,
            lineItem.draft,
          ),
        } as LineItem;
      })
      .filter(lineItem => {
        return statuses.includes((lineItem as any)?.status)
      })
      .reduce((unique, lineItem) => {
        if (!unique.some(c => c.id === lineItem.id)) {
          unique.push(lineItem as any);
        }
        return unique;
      }, [] as LineItem[]);
  }, [items, staticItems, order, orderBy, statuses]);

  return (
    <TableContainer>
      <Table>
        <SortableTableHead
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />

        <TableBody>
          {sortedAdGroups.map((adGroup, index) => {
            if (adGroup.url && adGroup.url.includes('static')) {
              return (
                <AdGroupsTableStaticDisplayRow
                  key={adGroup.id}
                  display={adGroup}
                  campaignId={campaignId}
                  campaign={campaign}
                  index={index}
                  handleOpenMenu={handleOpenMenu}
                  handleCloseMenu={handleCloseMenu}
                  currentAnchorEl={currentAnchorEl}
                  targetMenu={targetMenu}
                  handleManageDisplay={handleManageDisplay}
                />
              );
            }

            return (
              <AdGroupsTableLineItemRow
                key={adGroup.id}
                adGroup={adGroup}
                campaignId={campaignId}
                campaign={campaign}
                index={index}
                handleOpenMenu={handleOpenMenu}
                handleCloseMenu={handleCloseMenu}
                currentAnchorEl={currentAnchorEl}
                targetMenu={targetMenu}
                handleManageAdGroup={handleManageAdGroup}
                handleDuplicateAdGroup={handleDuplicateAdGroup}
                handleOpenWeighting={handleOpenWeighting}
                handlePauseActive={handlePauseActive}
                handleOpenBid={handleOpenBid}
                handleIsDuplicatable={handleIsDuplicatable}
                handleOpenRealTimeModal={handleOpenRealTimeModal}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
