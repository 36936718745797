import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useState, useContext } from 'react';
import { useUser } from '@components/hooks';
import AdvertiserContext from '@components/AdvertiserContext';
import { Themes } from '@constants/themes';
import {
  customInventoryTabValues,
  defaultCustomInventoryTabs,
  inventoryCategoryName,
  padmanCustomInventoryTabs,
} from '../../constants';
import { ReactiveIconSmall } from '../../styles';
import { useAdvancedGeneralTable } from './useAdvancedGeneralTable';
import { useTabs } from './useTabs';
import { getNonEmptyArrayValidation } from './validation';
import { useAdvancedCustomInventoryData } from './useAdvancedCustomInventoryData';
import { first, flatMap, map } from 'lodash';
import { addAction } from '../../../Advanced';
import { labels } from '@components/WizardContainers/GroupSection/constants';

const rightSideGroupField = 'type';
const dataFieldName = 'display_name';

export const useAdvancedCustomInventoryTable = ({ data }) => {
  const { items, selected } = data;
  const { user } = useUser();
  const { theme } = useContext(AdvertiserContext);
  const customInventoryTabs =
    theme === Themes.NBCU
      ? padmanCustomInventoryTabs
      : defaultCustomInventoryTabs;
  const [groupByFieldName, setGroupByFieldName] = useState();
  const [filterToRecommended, setFilterToRecommended] = useState(false);

  const filteredTabs = useMemo(() => {
    const isInternal = user.is_tvsci_employee;
    return customInventoryTabs.filter(tab => isInternal || !tab.internalOnly);
  }, [user]);

  const { tab, tabs, setTab } = useTabs(filteredTabs);

  const filteredData = useMemo(() => {
    switch (tab) {
      case customInventoryTabValues.dealId:
      case customInventoryTabValues.appBundleList:
      case customInventoryTabValues.appName:
        // TODO: Filter items for these tabs once API is returning them
        return [];
      case customInventoryTabValues.inventory:
        setGroupByFieldName('type');
        return items.filter(
          ({ recommended }) => !filterToRecommended || recommended
        );
      case customInventoryTabValues.channels:
      default:
        setGroupByFieldName('');
        return items.filter(
          i => i.type === 'Network' && (!filterToRecommended || i.recommended)
        );
    }
  }, [items, filterToRecommended, tab]);

  const legend = useMemo(() => {
    const legendList = [];

    if (filteredData.some(i => i.featured)) {
      legendList.push({
        type: 'color',
        value: 'chart.6',
        label: `Featured ${tab}`,
      });
    }

    if (filteredData.some(i => i.recommended)) {
      legendList.push({
        type: 'node',
        label: `Recommended ${tab}`,
        value: <ReactiveIconSmall name="reactive" />,
      });
    }

    return legendList;
  }, [filteredData, tab]);

  const tableProps = useAdvancedGeneralTable({
    data: filteredData,
    selected,
    workflowName: 'Advanced Custom Inventory',
    paneKey: labels.customInventory.value,
    dataFieldName,
    groupByFieldName,
    groupSelectedByFieldName: rightSideGroupField,
    resolver: () => yupResolver(getNonEmptyArrayValidation()),
    featuredFieldName: 'Featured Genres',
    // recommendedInventory,
  });

  const handleSetSelected = (v, { portion, action, selected }) => {
    if (action === addAction.Remove) {
      tableProps.setSelected(v);
      return;
    }

    const portionOptimized = portion[0].groupName
      ? flatMap(map(portion, p => p.data))
      : portion;

    const lookupElement = first(portionOptimized);

    const sameType = selected.find(
      ({ type, id }) => lookupElement.type === type && lookupElement.id !== id
    );

    const included =
      lookupElement.type ===
      inventoryCategoryName[customInventoryTabValues.dealId]
        ? false
        : !sameType || sameType.included;

    tableProps.setSelected([
      ...portionOptimized.map(p => ({
        ...p,
        included,
      })),
      ...selected,
    ]);
  };

  // For the "App Name", "App List ID", and "Deal ID" tabs we have to filter remotely
  const inventoryData = useAdvancedCustomInventoryData({
    tab,
    selected: tableProps.selected,
    dataFieldName,
    filter: tableProps.filter,
  });

  const handleCheckGroup = ({ groupName, value }) => {
    tableProps.setSelected(
      tableProps.selected.map(v => ({
        ...v,
        included: v[rightSideGroupField] === groupName ? value : v.included,
      }))
    );
  };

  const handleIncludeAll = () => {
    tableProps.setSelected(
      tableProps.selected.map(s => ({
        ...s,
        included:
          s.type === inventoryCategoryName[customInventoryTabValues.dealId]
            ? false
            : true,
      }))
    );
  };

  const handleExcludeAll = () => {
    tableProps.setSelected(
      tableProps.selected.map(s => ({ ...s, included: false }))
    );
  };

  const handleToggleViewRecommended = () => {
    setFilterToRecommended(prev => !prev);
  };

  const showRecommendedAlert = filteredData.some(
    ({ recommended }) => recommended
  );

  return {
    ...tableProps,
    ...inventoryData,
    legend,
    showRecommendedAlert,
    tab,
    tabs,
    viewRecommended: filterToRecommended,
    setTab,
    setSelected: handleSetSelected,
    checkGroup: handleCheckGroup,
    includeAll: handleIncludeAll,
    exludeAll: handleExcludeAll,
    toggleViewRecommended: handleToggleViewRecommended,
  };
};
