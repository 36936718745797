import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import { ConditionalTooltip } from '../ConditionalTooltip';

const blankCellStyles = {
  maxWidth: 40,
  width: 40,
  paddingLeft: 1,
  paddingRight: 1,
};

const denseStyles = {
  color: 'rgba(114, 115, 131, 0.8)',
  fontSize: '.8rem',
  lineHeight: 'normal',
};

const tableHeaderStyles = {
  ...denseStyles,
  minWidth: 100,
};

const SortableTableHead = ({
  blankColumn,
  dense,
  headCells,
  isSelectAll,
  order,
  orderBy,
  onRequestSort,
  onSelectAll,
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {blankColumn === 'start' && <TableCell sx={blankCellStyles} />}

        {onSelectAll && (
          <TableCell padding={dense ? 'none' : 'checkbox'}>
            <Checkbox
              checked={isSelectAll}
              size="small"
              onChange={onSelectAll}
              inputProps={{ 'aria-label': 'select all campaigns' }}
            />
          </TableCell>
        )}

        {headCells.map(cell => (
          <TableCell
            key={cell.id}
            align={cell.align ? cell.align : 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === cell.id ? order : false}
            {...cell.cellProps}
            sx={{
              ...(dense ? denseStyles : tableHeaderStyles),
              ...cell.cellProps?.sx,
            }}
          >
            {!cell.sortable ? (
              cell.label
            ) : (
              <ConditionalTooltip title={cell.titleTooltip}>
                <TableSortLabel
                  active={orderBy === cell.id}
                  direction={orderBy === cell.id ? order : 'asc'}
                  onClick={createSortHandler(cell.id)}
                >
                  {cell.label}
                </TableSortLabel>
              </ConditionalTooltip>
            )}
          </TableCell>
        ))}

        {blankColumn === 'end' && <TableCell sx={blankCellStyles} />}
      </TableRow>
    </TableHead>
  );
};

SortableTableHead.propTypes = {
  headCells: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  isSelectAll: PropTypes.bool,
  blankColumn: PropTypes.string,
  dense: PropTypes.bool,
};

export default SortableTableHead;
