import React from 'react';
import { Typography } from '@mui/material';
import { SectionHeader } from './SectionHeader';
import { headerTypes, headerMessage } from './constants';
import { OutOfSyncErrorIcon } from '@components/icons';

export const OutOfSyncHeader = () => (
  <SectionHeader sx={{ backgroundColor: 'tip.light' }}>
    <OutOfSyncErrorIcon />
    <Typography>{headerMessage[headerTypes.outOfSync]}</Typography>
  </SectionHeader>
);
