import { useCallback } from 'react';
import moment from 'moment';
import { last, map, set } from 'lodash';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import reportingApi from "@connection/reportingApiClient";
import { useCurrentSession } from '../currentSession';
import { useInfiniteGlobalCacheUpdate, useLoadInfinite } from './general';

const updateGroup = (data, prevData = []) =>
  map(prevData, page => ({
    ...page,
    results: map(page.results, result =>
      result.id === data.id ? { ...result, ...data } : result,
    ),
  }));

const addGroup = (data, prevData = []) =>
  map(prevData, page => ({
    ...page,
    results: map(page.results, result =>
      result.id === data.temporaryId ? data : result,
    ),
  }));

export const defaultAdGroupName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Ad Group`;
};

const findDataPage = (id, data) => {
  const index = data.findIndex(page =>
    page.results.find(result => result.id === id),
  );
  return index === -1 ? 0 : index + 1;
};

const buildAdGroupCacheKey = (adGroupId, currentAdvertiser, params = {}) => {
  const url = `/lineitems/${adGroupId}/`;

  return { url, advertiser: currentAdvertiser.id, ...params };
};

const buildPageCacheKey = (page, currentAdvertiser, params = {}) => {
  const url = '/lineitems/';

  const advertiser = currentAdvertiser.id;

  return {
    url,
    advertiser,
    params: {
      advertiser,
      page,
      ...params,
    },
  };
};

export const useGetAdGroup = (adGroupId, options) => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data);

  const swr = useSWR(
    apiIsReady && adGroupId
      ? buildAdGroupCacheKey(adGroupId, currentAdvertiser)
      : null,
    fetcher,
    options,
  );

  const { data, error, isLoading } = swr;

  return {
    adGroup: data,
    error,
    isLoading,
  };
};

export const usePatchAdGroup = initOptions => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateAdGroup = ({ url }, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {
    onSuccess: updatedAdGroup => {
      const key = buildAdGroupCacheKey(updatedAdGroup.id, currentAdvertiser);
      mutate(key, updatedAdGroup, { populateCache: true });
    },
    revalidate: false,
    ...initOptions,
  };

  return useSWRMutation(
    apiIsReady && currentAdvertiser.id ? { url: '/lineitems/' } : null,
    updateAdGroup,
    options,
  );
};

export const useDeleteAdGroup = options => {
  const { delV1, apiIsReady, currentAdvertiser } = useCurrentSession();

  const deleteAdGroup = (url, { arg: id }) =>
    delV1(`${url}${id}/`).then(res => res.data);

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady && currentAdvertiser.id ? `/lineitems/` : null,
    deleteAdGroup,
    {
      revalidate: true,
      ...options,
    },
  );

  return { trigger, isMutating };
};

export const usePatchAdGroups = options => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();

  const updateAdGroups = async ({ url }, { arg: updates }) =>
    await Promise.all(
      updates.map(({ id, ...data }) =>
        patch(`${url}${id}/`, data).then(res => res.data),
      ),
    );

  return useSWRMutation(
    apiIsReady && currentAdvertiser.id ? { url: '/lineitems/' } : null,
    updateAdGroups,
    options,
  );
};

export const useCreateAdGroup = initOptions => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const fetcher = ({ url }, { arg: { temporaryId, ...data } }) =>
    post(url, data).then(res => ({ ...res.data, temporaryId }));

  const options = {
    onSuccess: newAdGroup => {
      const key = buildAdGroupCacheKey(newAdGroup.id, currentAdvertiser);
      mutate(key, newAdGroup, { populateCache: true });
    },
    revalidate: false,
    ...initOptions,
  };

  return useSWRMutation(
    apiIsReady && currentAdvertiser.id ? { url: '/lineitems/' } : null,
    fetcher,
    options,
  );
};

export const useCampaignAdGroupsPage = (campaignId, options, url = '/lineitems/') => {
  const { patchV1, delV1, currentAdvertiser } = useCurrentSession();

  const { trigger: triggerGlobalCacheUpdate } =
    useInfiniteGlobalCacheUpdate();

  const { data, error, isLoading, mutate, items } = useLoadInfinite(
    url,
    {
      params: {
        campaign: Number(campaignId),
        disabled: !campaignId,
        v1: !!options?.v1,
      },
      keyGenerator: adGroup =>
        buildAdGroupCacheKey(adGroup.id, currentAdvertiser),
      ...options,
    },
    {
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const { trigger: triggerGroupUpdate } = usePatchAdGroup({
    onSuccess: updatedAdGroup => {
      triggerGlobalCacheUpdate(
        buildPageCacheKey(
          findDataPage(updatedAdGroup.id, data),
          currentAdvertiser,
          {
            campaign: Number(campaignId),
          },
        ),
        updateGroup(updatedAdGroup, data),
      );
    },
  });

  const { trigger: triggerGroupCreate } = useCreateAdGroup({
    onSuccess: newAdGroup => {
      triggerGlobalCacheUpdate(
        buildPageCacheKey(data.length, currentAdvertiser, {
          campaign: Number(campaignId),
        }),
        addGroup(newAdGroup, data),
      );
    },
  });

  const update = useCallback(
    updatedData => triggerGroupUpdate(updatedData),
    [triggerGroupUpdate],
  );

  const add = useCallback(
    predefinedData =>
      mutate(prevData => {
        const lastPage = { ...last(prevData) };

        const newResult = {
          id: (lastPage?.results?.length ?? 0) + 1,
          temporary: true,
          ...predefinedData,
        };

        return [
          ...(prevData?.slice(0, -1) ?? []),
          {
            ...lastPage,
            results: [...(lastPage?.results ?? []), newResult],
          },
        ];
      }, false),
    [mutate],
  );

  const remove = useCallback(
    id =>
      mutate(async prevData => {
        const pageWithItem = prevData.find(page =>
          page.results.find(result => result.id === id),
        );
        const { temporary = false } =
          pageWithItem?.results.find(result => result.id === id) || {};

        if (!temporary) {
          await patchV1(`${url}${id}/`, { creatives: [] });
          await delV1(`${url}${id}/`);
        }

        return prevData.map(page => {
          const updatedResults = page.results.filter(
            result => result.id !== id,
          );
          return set({ ...page }, 'results', updatedResults);
        });
      }, false),
    [mutate],
  );

  const create = useCallback(
    data => triggerGroupCreate({ ...data, temporaryId: data.id }),
    [triggerGroupCreate, remove],
  );

  const duplicate = useCallback(
    (id, extraData) =>
      mutate(prevData => {
        return prevData.map(page => {
          const itemToDuplicate = page.results.find(
            result => result.id === id,
          );
          if (!itemToDuplicate) return page;

          const duplicatedItem = {
            ...itemToDuplicate,
            name: itemToDuplicate.name
              ? `${itemToDuplicate.name} Duplicate`
              : defaultAdGroupName(),
            id: page.results.length + 1,
            temporary: true,
            ...(extraData ?? {}),
          };

          return {
            ...page,
            results: [...page.results, duplicatedItem],
          };
        });
      }, false),
    [mutate],
  );

  return {
    items,
    data,
    error,
    isLoading,
    add,
    create,
    remove,
    update,
    duplicate,
    mutate,
  };
};

export const useAdGroupWinsReporting = (adGroupId, params, options) => {
  const swr = useSWR(
    adGroupId ? { url: `/adgroup_wins/${adGroupId}`, params } : null,
    ({ url, params }) => reportingApi.get(url, { params }).then(res => res.data),
    options,
  );

  const { data, wins } = swr.data ?? {};

  return {
    ...swr,
    data: {
      wins: wins ?? 0,
      data: data?.dataPoints.map(item => ({
        wins: item.count,
        date: new Date(item.timestamp),
      })),
    },
  }
}
