import { useContext, useMemo } from 'react';
import { useDomain } from '@hooks/domain';
import AdvertiserContext from '@components/AdvertiserContext';
import { useBWSync } from '@hooks/bwSync';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';

export const useGetTableHeaders = () => {
  const domain = useDomain();
  const adContext = useContext(AdvertiserContext);
  const { showBWSync } = useBWSync();
  const { realtimeDeliveryModal } = useLDFlags();

  const isBidEnabled = useMemo(
    () => adContext.tvsciq && adContext.tvsciq !== 'DISABLED',
    [adContext.tvsciq],
  );

  return useMemo(() => {
    const cells = [
      { id: 'id', sortable: true, label: 'ID', disablePadding: false },
      {
        id: 'name',
        sortable: true,
        label: 'Ad Group',
        disablePadding: false,
      },

      realtimeDeliveryModal && {
        id: 'realtimeDelivery',
        sortable: true,
        label: '',
        disablePadding: false,
        cellProps: { sx: { minWidth: 'auto' } },
      },
      {
        id: 'campaignName',
        sortable: false,
        label: 'Campaign',
        disablePadding: false,
      },
      {
        id: 'start_date',
        sortable: true,
        label: 'Start date',
        disablePadding: false,
      },
      {
        id: 'end_date',
        sortable: true,
        label: 'End date',
        disablePadding: false,
      },
      {
        id: 'maxBid',
        sortable: true,
        label: 'Max Bid',
        disablePadding: false,
      },
      { id: 'spend', sortable: true, label: 'Spend', disablePadding: false },
    ];

    if (domain.default) {
      cells.push({
        id: 'budgetRemaining',
        sortable: true,
        label: 'Budget remaining',
        disablePadding: false,
      });
    }

    cells.push(
      {
        id: 'visits',
        sortable: true,
        label: 'Visits',
        disablePadding: false,
      },
      {
        id: 'costPerVisit',
        sortable: true,
        label: 'Cost per visit',
        disablePadding: false,
      },
    );

    if (isBidEnabled) {
      cells.push({
        id: 'biddingStrategy',
        sortable: false,
        label: 'Bidding Strategy',
        disablePadding: false,
      });
    }

    if (showBWSync) {
      cells.push({
        id: 'adServerCheck',
        sortable: false,
        label: 'Ad Server Check',
        disablePadding: false,
      });
    }

    cells.push({
      id: 'deliverySignal',
      sortable: false,
      label: 'Delivering',
      disablePadding: false,
    });

    cells.push(
      {
        id: 'status',
        sortable: true,
        label: 'Status',
        disablePadding: false,
      },
      {
        id: 'action',
        sortable: false,
        label: 'Action',
        disablePadding: false,
      },
    );

    return cells.filter(Boolean);
  }, [domain.default, isBidEnabled, showBWSync, realtimeDeliveryModal]);
};
