import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useSearchParams = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const setSearchParams = (newQueryParams: { key: string, value: string }[] | { key: string, value: string }) => {
    const url = new URL(window.location.href);
    if (Array.isArray(newQueryParams)) {
      newQueryParams.forEach(({ key, value }) => {
        url.searchParams.set(key, value);
      })
    } else {
      const { key, value } = newQueryParams
      url.searchParams.set(key, value);
    }
    window.history.pushState(null, '', url.toString());
  }

  return { searchParams, setSearchParams }
}
