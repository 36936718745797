import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Container,
  IconButton,
  Modal,
  styled,
  Typography,
} from '@mui/material';

export const StyledModalWrapper = styled(Modal)({
  maxWidth: '800px',
  margin: 'auto',
});

export const StyledContainer = styled(Container)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(3.75),
  padding: theme.spacing(5.625, 10),
}));

export const StyledUnderline = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[10],
  height: theme.spacing(0.125),
  width: '100%',
}));

export const StyledDangerButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.red[2],
}));

export const StyledWarningTypography = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(4),
  paddingLeft: 0,
}));

export const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(5),
  top: theme.spacing(5),
}));

export const StyledCloseIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  color: theme.palette.grey[2],
}));
