import { useWizardContext } from './useWizardContext';

export const useWizardPreview = ({ key, group }) => {
  const {
    navigation: { activeSection },
  } = useWizardContext();
  const showOutOfSync = Boolean(group?.out_of_sync?.beeswax_targeting);
  return {
    active: key === activeSection,
    showOutOfSync,
  };
};
