import { MenuItem } from '@mui/material';
import React from 'react';

import { GetPacingStringsProps, LabelValuePair } from './types';

export const getPacingShortLabel = ({
  pacingOptions,
  selected,
}: {
  pacingOptions: LabelValuePair[];
  selected: string;
}) => {
  if (selected === 'DAILY') {
    return 'Daily';
  }

  const pacingOption = pacingOptions.find(({ value }) => value === selected);

  return pacingOption?.label;
};

export const getPacingStrings = ({
  budgetPacingOptions: {
    behavior: behaviorOptions,
    catchup: catchupOptions,
    pacing: pacingOptions,
  },
  pacing,
  pacingBehavior,
  pacingCatchupBehavior,
}: GetPacingStringsProps) => {
  const pacingString = getPacingShortLabel({
    pacingOptions,
    selected: pacing,
  });

  const behaviorOption = behaviorOptions.find(
    ({ value }) => value === pacingBehavior
  );
  const behaviorString = behaviorOption?.label;

  const catchupOption = catchupOptions.find(
    ({ value }) => value === pacingCatchupBehavior
  );
  const catchupString = catchupOption?.label;

  return pacing === 'NO_PACING'
    ? [pacingString]
    : [pacingString, behaviorString, catchupString];
};

export const renderOptions = (options: LabelValuePair[]) =>
  options.map(({ label, value }) => (
    <MenuItem key={value} data-testid={`${value}-option`} value={value}>
      {label}
    </MenuItem>
  ));

export const renderValue = ({
  options,
  selected,
  subOptionsDisabled,
}: {
  options: LabelValuePair[];
  selected: string;
  subOptionsDisabled: boolean;
}) => {
  if (subOptionsDisabled) return '--';

  const option = options.find(
    ({ value }) => value === selected
  );
  return option?.label;
};
