import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useContext, useState } from 'react';
import { mapCreativesWithLocalAssets } from '../utils';
import { WizardPageContext } from '@components/containers/WizardPage/constants';

export const useCreative = () => {
  const { localAssets } = useContext(WizardPageContext);
  const [showMore, setShowMore] = useState(false);
  const { watch } = useFormContext();

  const creatives = watch(fields.creatives.path) ?? [];

  return {
    creatives: mapCreativesWithLocalAssets(creatives, localAssets),
    showMore,
    setShowMore,
  };
};
