import React from 'react';
import { dialogStylesOverride } from './styles';
import { AdServerOutOfSyncPromptProps } from './types';
import { useAdServerOutOfSyncPrompt } from './hooks/useAdServerOutOfSyncPrompt';
import { Title } from './Title';
import { Content } from './Content';
import { Actions } from './Actions';

// Comply with src/components/Dialog.js
export const adServerOutOfSyncPrompt = ({
  open,
  handleClose,
  handleMerge,
  handleOverwrite,
  outOfSyncItems,
}: AdServerOutOfSyncPromptProps) => {
  const { merge, overwrite } = useAdServerOutOfSyncPrompt({
    handleClose,
    handleMerge,
    handleOverwrite,
  });

  return {
    isOpen: open,
    title: Title({ handleClose }),
    content: Content({ outOfSyncItems }),
    actions: Actions({ merge, overwrite }),
    onClose: handleClose,
    showDefaultCloseButton: false,
    dialogStylesOverride,
  };
};
