import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { View } from './constants';
import AppHeader from '../../AppHeader';
import { AddCreativeView, CreativesView } from './containers';

export const ViewComponents = {
  [View.VIEW_CREATIVES]: CreativesView,
  [View.ADD_CREATIVE]: AddCreativeView,
};

const CreativeIndexPage = ({ history }) => {
  const [view, setView] = useState(View.VIEW_CREATIVES);

  const ViewComponent = useMemo(() => ViewComponents[view], [view]);

  const handleActionChange = newAction => {
    setView(newAction);
  };

  return (
    <AppHeader history={history}>
      <ViewComponent onActionChange={handleActionChange} />
    </AppHeader>
  );
};

CreativeIndexPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default CreativeIndexPage;
