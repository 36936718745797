import useSWR from 'swr';
import { useCurrentSession } from '../currentSession';
import { useFlags } from '../flags';
import {
  BudgetPacingOptions,
} from '@v2/components/campaign/PacingFieldsGroup';

export const useGetBudgetPacingOptions = () => {
  const { apiIsReady, get } = useCurrentSession();
  const { flags, Flags } = useFlags();
  const pacingAllowed = flags[Flags.USER_GETS_VERTICAL_PACING];

  const fetcher = async (url: string): Promise<BudgetPacingOptions> =>
    get(url).then(res => res.data);

  const url = 'budgetpacingoptions/introspection/';

  return useSWR(apiIsReady && pacingAllowed ? url : null, fetcher);
};
