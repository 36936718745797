import React from 'react';
import {
  faArrowRotateLeft,
  faBoxArchive,
} from '@fortawesome/pro-regular-svg-icons';
import { Box } from '@mui/material';

import { Statuses } from '@components/containers/CampaignsPage/statuses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CampaignArchiveRenderer = ({ status }: { status: number }) => {
  if (status === Statuses.PAUSED) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <FontAwesomeIcon icon={faBoxArchive} fontSize="20px" />
        Archive Campaign
      </Box>
    );
  }

  if (status === Statuses.ARCHIVED) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <FontAwesomeIcon icon={faArrowRotateLeft} fontSize="20px" />
        Unarchive Campaign
      </Box>
    );
  }

  return null;
};
