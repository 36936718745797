import { useEffect } from "react";
import reportingApi from "@connection/reportingApiClient";
import { getPrimaryOrgId } from "@helpers/getPrimaryOrgId";
import { useAuth } from "./auth";
import { useQuery } from "./query";
import { useCurrentSession } from "./currentSession";

export const useReportingApi = () => {
  const { authState } = useAuth();
  const urlQuery = useQuery();
  const { currentAdvertiser } = useCurrentSession();

  useEffect(() => {
    if (authState.accessToken) {
      reportingApi.defaults.headers.common['Authorization'] =
      `Bearer ${authState.accessToken}`;
    }
  }, [authState.accessToken]);

  useEffect(() => {
    const sessionAdAccount = urlQuery.get('adAccount');
    const sessionOrg = urlQuery.get('org');

    if (sessionAdAccount) {
      reportingApi.defaults.headers.common['X-TVS-AdvertiserContext'] = sessionAdAccount;
      reportingApi.defaults.headers.common['X-TVS-OrganizationContext'] = getPrimaryOrgId(sessionOrg);
    } else if (currentAdvertiser?.id !== 0) {
      reportingApi.defaults.headers.common['X-TVS-AdvertiserContext'] = currentAdvertiser.id;
      reportingApi.defaults.headers.common['X-TVS-OrganizationContext'] = getPrimaryOrgId(
        currentAdvertiser.primary_org,
      );
    }
  }, [currentAdvertiser]);
};
