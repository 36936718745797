import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(MuiDialog)(({ theme, styleOverride }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
    color: '#13171a',

    '& .MuiTypography-colorTextSecondary': {
      color: 'inherit',
    },

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: theme.spacing(2.5),
    },

    '& .MuiDialogActions-root': {
      padding: theme.spacing(4.5, 0, 0, 0),
    },
  },
  ...(typeof styleOverride === 'function'
    ? styleOverride(theme)
    : styleOverride),
}));

const CloseButton = styled(IconButton)(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: spacing(1),
  top: spacing(1),
  color: '#435159',
}));

const StyledDialogContent = styled(DialogContent)(
  ({ theme: { spacing } }) => ({
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  })
);

const Dialog = ({
  isOpen,
  title,
  content,
  actions,
  onClose,
  showDefaultCloseButton = true,
  dialogStylesOverride,
  ...props
}) => (
  <StyledDialog
    fullWidth
    maxWidth="sm"
    open={isOpen}
    onClose={onClose}
    aria-labelledby="terms-dialog"
    styleOverride={dialogStylesOverride}
    {...props}
  >
    {onClose && showDefaultCloseButton && (
      <CloseButton aria-label="close" onClick={onClose} size="large">
        <CloseIcon />
      </CloseButton>
    )}

    <DialogTitle id="terms-dialog">{title}</DialogTitle>

    <Box mx={3}>
      <Divider />
    </Box>

    <StyledDialogContent>{content}</StyledDialogContent>

    <DialogActions>{actions}</DialogActions>
  </StyledDialog>
);

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  content: PropTypes.node.isRequired,
  actions: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  showDefaultCloseButton: PropTypes.bool,
  dialogStylesOverride: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  props: PropTypes.object,
};

export default Dialog;
