import * as yup from 'yup';
import { fields } from '../constants';

export const MediaTypes = {
  image: 'image',
  video: 'video',
};

export const schema = yup.object().shape({
  [fields.files.path]: fields.files.rule,
});
