import React, { createContext, useState, useContext } from 'react';

export interface Filters {
  [key: string]: string
}

interface LookerFilterContextType {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string }>> | null;
}

const LookerFiltersContext = createContext<LookerFilterContextType>({
  filters: {},
  setFilters: null,
});

export const LookerFiltersContextProvider = ({ children }: {children: React.ReactElement}) => {
  const [filters, setFilters] = useState({});

  return (
    <LookerFiltersContext.Provider value={{ filters, setFilters }}>
      {children}
    </LookerFiltersContext.Provider>
  );
};

export const useLookerFiltersContext = () => {
  return useContext(LookerFiltersContext);
};
