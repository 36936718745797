import React from 'react';
import { Box, Grid } from '@mui/material';
import { WizardProvider } from './WizardProvider';
import { useWizard } from './hooks/useWizard';
import { wizardProps } from './types';
import { Sections } from './components';
import { flatMap } from 'lodash';
import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

const getSectionProp = (children, propName) =>
  flatMap(
    Array.isArray(children)
      ? children.map(child => getSectionProp(child, propName))
      : [children.props?.[propName]]
  ).filter(Boolean);

const getSectionProps = sectionsElements => {
  return {
    sections: getSectionProp(sectionsElements, 'sectionKey'),
    sectionNames: getSectionProp(sectionsElements, 'sectionName'),
  };
};

export const Wizard = ({ children }) => {
  const sectionsWrapper = children.find(child => child.type === Sections);
  const { sections, sectionNames } = getSectionProps(
    sectionsWrapper.props.children
  );

  const { navigation } = useWizard({ sections, sectionNames });

  return (
    <WizardProvider navigation={navigation}>
      <Box sx={{ px: 6, py: 6 }}>
        <Grid container spacing={4} justifyContent="space-between">
          {children}
        </Grid>
      </Box>
    </WizardProvider>
  );
};

Wizard.propTypes = {
  ...wizardProps,
};
