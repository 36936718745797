import React, { useState, useEffect, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { Box, Container, Grid, Stack, Paper, Tabs, Tab, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { EmbedLookerChart } from '../../EmbedLookerChart';
import AppHeader from '../../AppHeader';
import AdvertiserContext from '../../AdvertiserContext';
import { useUser } from "@hooks/index";
import { Themes } from '@constants/index';
import { PathToPurchase } from '@components/containers/ReportsPage/PathToPurchase';
import { useDomain } from '@hooks/domain';
import { useRoles } from '@components/hooks/roles';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useSearchParams } from '@hooks/useSearchParams';
import { LookerFiltersContextProvider } from '@providers/LookerFiltersContext'
import { useHistory } from 'react-router-dom';

const PREFIX = 'ReportsPage';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  dashboard: `${PREFIX}-dashboard`,
  select: `${PREFIX}-select`,
  tabs: `${PREFIX}-tabs`,
};

const StyledAppHeader = styled(AppHeader)(({ theme }) => ({
  [`& .${classes.container}`]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    maxWidth: 'none',
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(5),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    minHeight: 1200,
  },

  [`& .${classes.dashboard}`]: {
    '& > iframe': {
      width: '100%',
      height: 1100,
    }
  },

  [`& .${classes.select}`]: {
    width: 200,
  },

  [`& .${classes.tabs}`]: {
    minWidth: 200,
    margin: '0 20px',

    ['& .MuiTypography-root']: {
      fontSize: '1rem',
    },

    ['&.Mui-selected .MuiTypography-root']: {
      fontWeight: 700,
    }
  },
}));

const CampaignPerformanceDashboard = {
  LOOKER_V1: '',
  LOOKER_V2: 'CAMPAIGN_PERFORMANCE',
};

const IncrementalityDashboards = {
  [Themes.DEFAULT]: 'INCREMENTALITY',
  [Themes.NBCU]: 'NBCU_INCREMENTALITY',
};

const ReportsPage = () => {
  const { user } = useUser();
  const roles = useRoles();
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { reportsLoadAllIframes } = useLDFlags();
  const history = useHistory();
  const [inExplore, setInExplore] = useState(false);

  const [openTab, setOpenTab] = useState<string | null>('performance');

  const { searchParams, setSearchParams } = useSearchParams();

  const showLookerDashboard = useMemo(
    () => adContext?.uses_looker && adContext?.looker_validated,
    [adContext?.uses_looker, adContext?.looker_validated],
  );

  const campaignPerformanceDashboard = useMemo(() => {
    if (domain.peacock) {
      return CampaignPerformanceDashboard.LOOKER_V1;
    }

    if (adContext?.cost_model === 'CPA') {
      return CampaignPerformanceDashboard.LOOKER_V2;
    }

    if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
      return CampaignPerformanceDashboard.LOOKER_V2;
    }

    return CampaignPerformanceDashboard.LOOKER_V1;
  }, [domain.peacock, adContext?.cost_model, showLookerDashboard]);

  const incrementalityDashboard = useMemo(
    () => IncrementalityDashboards[adContext.theme],
    [adContext.theme]
  );

  const tabs = useMemo(() => {
    const tabsList: { id: string, title: string, content: () => JSX.Element }[] = [];
    if (!adContext.name) return tabsList;

    if (domain.peacock && adContext?.cost_model === 'CPM') {
      tabsList.push({
        id: 'deliveryNBCU',
        title: 'Campaign Delivery',
        content: () => (
          <EmbedLookerChart
            dashboard="NBCU_CAMPAIGN_DELIVERY"
            setInExplore={setInExplore}
          />
        )
      });

      tabsList.push({
        id: 'performanceNBCU',
        title: 'Campaign Performance',
        content: () => (
          <EmbedLookerChart
            dashboard="NBCU_CAMPAIGN_PERFORMANCE"
            setInExplore={setInExplore}
          />
        )
      });

      if (adContext.path_to_purchase_validated) {
        tabsList.push({
          id: 'path',
          title: 'Path to Purchase',
          content: () => showLookerDashboard ? (
            <PathToPurchase
              setInExplore={setInExplore}
            />
          ) : (
            <EmbedLookerChart
              dashboard={adContext.has_incrementality ? incrementalityDashboard : 'DATA_TABLE'}
              setInExplore={setInExplore}
            />
          )
        });
      }
    }

    if (domain.peacock && adContext?.cost_model === 'CPA') {
      tabsList.push({
        id: roles.TENANT_ADMIN ? 'cpaInternal' : 'cpaExternal',
        title: 'Campaign Performance',
        content: () => (
          <EmbedLookerChart
            dashboard={roles.TENANT_ADMIN ? 'CPA_INTERNAL_NBCU' : 'CPA_EXTERNAL_NBCU'}
            setInExplore={setInExplore}
          />
        )
      });
    }

    if (domain.default) {
      if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
        tabsList.push({
          id: 'delivery',
          title: 'Campaign Delivery',
          content: () => (
            <EmbedLookerChart
              dashboard="CAMPAIGN_DELIVERY"
              setInExplore={setInExplore}
            />
          )
        });
      }

      tabsList.push({
        id: 'performance',
        title: 'Campaign Performance',
        content: () => (
          <EmbedLookerChart
            dashboard={campaignPerformanceDashboard}
            setInExplore={setInExplore}
          />
        )
      });

      if (adContext?.cost_model === 'CPM' && showLookerDashboard) {
        tabsList.push({
          id: 'inventory',
          title: 'Inventory & Geo',
          content: () => (
            <EmbedLookerChart
              dashboard="INVENTORY_GEO"
              setInExplore={setInExplore}
            />
          )
        });
      }

      if (adContext.path_to_purchase_validated) {
        tabsList.push({
          id: 'path',
          title: 'Path to Purchase',
          content: () => showLookerDashboard ? (
            <PathToPurchase
              setInExplore={setInExplore}
            />
          ) : (
            <EmbedLookerChart
              dashboard={adContext.has_incrementality ? incrementalityDashboard : 'DATA_TABLE'}
              setInExplore={setInExplore}
            />
          )
        });
      }

      if (adContext.has_incrementality) {
        tabsList.push({
          id: 'incrementality',
          title: 'Campaign Incrementality',
          content: () => (
            <EmbedLookerChart
              dashboard={incrementalityDashboard}
              setInExplore={setInExplore}
            />
          )
        });
      }

      if (adContext?.has_sequential_events) {
        tabsList.push({
          id: 'conversions',
          title: 'Post Conversions',
          content: () => (
            <EmbedLookerChart
              dashboard="SEQUENTIAL_EVENTS"
              setInExplore={setInExplore}
            />)
        });
      }

      if (user?.is_tvsci_employee) {
        tabsList.push({
          id: 'beta',
          title: 'Beta',
          content: () => (
            <Stack direction="column" spacing={2}>
              <Typography variant="body2">
                This screen is displaying reports that are tested internally while being considered for wider release. Please share with customers, and collect their feedback.
              </Typography>
              <EmbedLookerChart
                dashboard="INTERNAL_EXPERIMENTAL"
                setInExplore={setInExplore}
              />
            </Stack>
          )
        });
      }
    }

    return tabsList;
  }, [
    domain.peacock,
    domain.default,
    adContext,
    showLookerDashboard,
    incrementalityDashboard,
    campaignPerformanceDashboard,
    user,
  ]);

  useEffect(() => {
    if (tabs.length === 0) return
    const activeParam = searchParams.get('active')
    const paramIsValid = tabs.some(tab => tab.id === activeParam)
    if (activeParam && paramIsValid) {
      setOpenTab(activeParam)
    } else {
      const id = tabs.length > 0 ? tabs[0].id : null;

      if (id) {
        setOpenTab(id);
        setSearchParams({ key: 'active', value: id })
      }
    }
  }, [adContext.id, tabs])

  const { content: TabContent } = useMemo<{ content?: () => JSX.Element }>(
    () => tabs.find(({ id }) => id === openTab) || {},
    [tabs, openTab]
  );

  const handleTabChange = (e: React.SyntheticEvent, v: string) => {
    if (inExplore) {
      history.goBack();
      setInExplore(false);
    }
    setOpenTab(v)
    setSearchParams({ key: 'active', value: v })
  }


  const handleBack = () => {
    setInExplore(false);
    history.goBack();
  }

  return (
    <LookerFiltersContextProvider>
      <StyledAppHeader>
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper
                className={clsx(classes.paper, 'background-img-inline-example')}
              >
                {adContext && (
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={openTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons={true}
                    >
                      {tabs.map(tab => (
                        <Tab
                          disableRipple
                          className={classes.tabs}
                          key={tab.id}
                          value={tab.id}
                          data-testid={`reports-page-tab-${tab.id}`}
                          label={
                            <Typography>
                              {tab.title}
                            </Typography>
                          }
                        />
                      ))}
                    </Tabs>
                  </Box>
                )}
                <Box position='relative'>
                  {inExplore && (
                    <Button
                      sx={{
                        position: 'absolute',
                        padding: 0,
                        top: '4px',
                        left: '10px',
                        fontSize: '10px',
                        "&.MuiButtonBase-root:hover": {
                          bgcolor: "transparent"
                        },
                        color: '#1DAFFF'
                      }}
                      onClick={handleBack}
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                    >
                      &lt; Back
                    </Button>
                  )}
                  {reportsLoadAllIframes ? (
                    <>
                      {tabs.map(tab => (
                        <div
                          key={tab.id}
                          style={{ display: openTab === tab.id ? 'unset' : 'none' }}
                          data-testid={`reports-iframe-container-${tab.id}`}
                        >
                          {tab.content()}
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {TabContent && <TabContent />}
                    </>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </StyledAppHeader>
    </LookerFiltersContextProvider >
  );
};
export default ReportsPage;
