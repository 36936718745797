import { Stack, styled } from '@mui/material';

export const SectionHeaderStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(2, 4),
  margin: theme.spacing(-6.5, -7, 2.5, -7),
  color: theme.palette.common.black,
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  zIndex: 999,
}));
