import React from 'react';
import { Stack } from '@mui/material';
import {
  StyledPromptContentTypography,
  StyledList,
  StyledListItem,
} from './styles';
import { contentProps } from './types';

export const Content = ({ outOfSyncItems }: contentProps) => {
  return (
    <Stack>
      <StyledPromptContentTypography variant="body2">
        The following field(s) on the ad server are out-of-sync with your Ad
        Group:
        <StyledList>
          {outOfSyncItems.map((item, index) => (
            <StyledListItem key={index}>
              <strong>{item}</strong>
            </StyledListItem>
          ))}
        </StyledList>
      </StyledPromptContentTypography>
      <StyledPromptContentTypography variant="body2">
        <br />
        How to fix:
        <StyledList>
          <StyledListItem>
            <strong>Overwrite Ad Server</strong> will completely replace all
            targeting with the settings you just saved.
          </StyledListItem>
          <StyledListItem>
            <strong>Merge with Ad Server</strong> will attempt to combine new
            and old settings.
          </StyledListItem>
        </StyledList>
      </StyledPromptContentTypography>
    </Stack>
  );
};
