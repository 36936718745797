import { useFormContext } from 'react-hook-form';
import { Copies, fields } from '../constants';
import { useContext } from 'react';
import AdvertiserContext from '@components/AdvertiserContext';
import { useCopy } from '@hooks/copy';
import { useDomain } from '@hooks/domain';
import { useFlags } from '@hooks/flags';
import { useRoles } from '@hooks/roles';
import { getPacingStrings } from '../../PacingFieldsGroup/utils';
import { useGetBudgetPacingOptions } from '@components/hooks/apis/budgetPacingOptions';

export const useBidStrategy = ({ onAdvanced }) => {
  const { flags, Flags } = useFlags();

  const { setValue, watch, trigger, control } = useFormContext();

  const domain = useDomain();
  const roles = useRoles();

  const Copy = useCopy(Copies);
  const adContext = useContext(AdvertiserContext);

  const {
    data: budgetPacingOptions = { behavior: [], catchup: [], pacing: [] },
  } = useGetBudgetPacingOptions();

  const isPacingAllowed = flags[Flags.USER_GETS_VERTICAL_PACING];

  const shouldShowBidStrategy =
    adContext?.bidstrategy_set?.length > 0 &&
    adContext.tvsciq &&
    adContext.tvsciq !== 'DISABLED';

  const [bundles, inventoryOption] = watch([
    fields.advancedCustomInventory.path,
    fields.inventoryOption.path,
  ]);

  const [
    frequencyCap = [],
    dayparting = [],
    isMaxCPMBidEnabled,
    isPacingEnabled,
    pacing,
    pacingBehavior,
    pacingCatchupBehavior,
  ] = watch([
    fields.configureAdGroupFrequencyCap.path,
    fields.configureAdGroupDayparting.path,
    fields.maxCPMBidEnabled.path,
    fields.adGroupPacingEnabled.path,
    fields.adGroupPacing.path,
    fields.adGroupPacingBehavior.path,
    fields.adGroupPacingCatchupBehavior.path,
  ]);

  const handleAdvanced = (index, type) => {
    setValue(
      fields.configureAdGroupFrequencyCap.path,
      frequencyCap?.map((v, i) => ({
        ...v,
        isEditing: i === index && type === 'frequencyCap',
      })) ?? []
    );

    setValue(
      fields.configureAdGroupDayparting.path,
      dayparting?.map((v, i) => ({
        ...v,
        isEditing: i === index && type === 'dayparting',
      })) ?? []
    );

    onAdvanced();
  };

  const pacingStrings = getPacingStrings({
    budgetPacingOptions,
    pacing,
    pacingBehavior,
    pacingCatchupBehavior,
  });

  const handleCPMSwitchChange = event => {
    setValue(fields.maxCPMBidEnabled.path, event.target.checked, {
      shouldDirty: true,
    });
    if (!event.target.checked) {
      setValue(fields.maxCPMBid.path, null, { shouldDirty: true });
    }
  };

  return {
    setValue,
    control,
    frequencyCap,
    domain,
    roles,
    Copy,
    trigger,
    adContext,
    dayparting,
    isMaxCPMBidEnabled,
    shouldShowBidStrategy,
    bundles,
    inventoryOption,
    advanced: handleAdvanced,
    isPacingAllowed,
    isPacingEnabled,
    pacingStrings,
    handleCPMSwitchChange,
  };
};
