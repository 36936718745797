export const isValidUrl = (url: string): boolean => {
  try {
    const parsedURL = new URL(url);

    if (!parsedURL.protocol.startsWith('https')) {
      return false;
    }

    if (!parsedURL.hostname.match(/\.(\w+)/)) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
};
