import { GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { get } from 'lodash';

export const DotNotationValueGetter = (
  value: any,
  row: Record<string, any>,
  columnDefinition: GridColDef
) => {
  return get(row, columnDefinition.field, '-');
};
